import React, {Component, Fragment} from 'react'
import SupportGuide from './SupportGuide';
import {haulcheckURl, assuredURL, url, assuredAPIURL, frontendURl} from '../../constants';
import NavbarCore from './NavbarCore';
import assuredPageUrls from '../../constants/assuredPageUrls';
import {Auth} from "aws-amplify";
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';

export default class Navbar extends Component {

    constructor(props){
        super(props);
        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        const username = userDecoded ? userDecoded['cognito:username'] : null;

        this.state = {
            username,
            userDecoded,
            iduser: null,
        }
    }

    componentWillMount = () => {

        ajax.post(`${assuredAPIURL}/users/auth`)
            .send(this.state.userDecoded)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({iduser: response.body.user.iduser});                    
                } else {
                    console.log('There was an error', error);
                }
            }
        );
    }

    render = () => {
        return(
            <Fragment>
                <NavbarCore
                    haulcheckFrontendURL={haulcheckURl}
                    assuredFrontendURL={assuredURL}
                    assuredAPIURL={assuredAPIURL}
                    eiFrontendUrl={frontendURl}
                    assuredPageUrls={assuredPageUrls}
                    app={"EarthIdeal"}
                    iduser={this.state.iduser}
                    username={this.state.username}
                    logo="../img/logo/ade-logo.png"
                    logout={() => {
                       window.location.href = url.frontend+"/logout";
                    }}
                />
                <SupportGuide />
            </Fragment>
        )
        //return null;
    }
}