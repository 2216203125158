import React from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import * as routes from '../../../constants/routes';

export default function PrivateRoute({ children, ...rest }) {
  const isAuthenticated = true ;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.LOGIN,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
