export const GET_ACTIVE_TICKETS_REQUESTED = "GET_ACTIVE_TICKETS_REQUESTED";
export const GET_ACTIVE_TICKETS_REJECTED = "GET_ACTIVE_TICKETS_REJECTED";
export const GET_ACTIVE_TICKETS_FULFILLED = "GET_ACTIVE_TICKETS_FULFILLED";

export const GET_HAULED_TODAY_TICKETS_REQUESTED = "GET_HAULED_TODAY_TICKETS_REQUESTED";
export const GET_HAULED_TODAY_TICKETS_REJECTED = "GET_HAULED_TODAY_TICKETS_REJECTED";
export const GET_HAULED_TODAY_TICKETS_FULFILLED = "GET_HAULED_TODAY_TICKETS_FULFILLED";

export const GET_CLOSED_TICKETS_REQUESTED = "GET_CLOSED_TICKETS_REQUESTED";
export const GET_CLOSED_TICKETS_REJECTED = "GET_CLOSED_TICKETS_REJECTED";
export const GET_CLOSED_TICKETS_FULFILLED = "GET_CLOSED_TICKETS_FULFILLED";

export const CREATE_NEW_TICKET_REQUESTED = "CREATE_NEW_TICKET_REQUESTED";
export const CREATE_NEW_TICKET_REJECTED = "CREATE_NEW_TICKET_REJECTED";
export const CREATE_NEW_TICKET_FULFILLED = "CREATE_NEW_TICKET_FULFILLED";

export const UPDATE_TICKET_SOURCE_PICTURE_REQUESTED = "UPDATE_TICKET_SOURCE_PICTURE_REQUESTED";
export const UPDATE_TICKET_SOURCE_PICTURE_REJECTED = "UPDATE_TICKET_SOURCE_PICTURE_REJECTED";
export const UPDATE_TICKET_SOURCE_PICTURE_FULFILLED = "UPDATE_TICKET_SOURCE_PICTURE_FULFILLED";

export const UPDATE_TICKET_SOURCE_GEO_REQUESTED = "UPDATE_TICKET_SOURCE_GEO_REQUESTED";
export const UPDATE_TICKET_SOURCE_GEO_REJECTED = "UPDATE_TICKET_SOURCE_GEO_REJECTED";
export const UPDATE_TICKET_SOURCE_GEO_FULFILLED = "UPDATE_TICKET_SOURCE_GEO_FULFILLED";

export const UPDATE_TICKET_ARRIVAL_PICTURE_REQUESTED = "UPDATE_TICKET_ARRIVAL_PICTURE_REQUESTED";
export const UPDATE_TICKET_ARRIVAL_PICTURE_REJECTED = "UPDATE_TICKET_ARRIVAL_PICTURE_REJECTED";
export const UPDATE_TICKET_ARRIVAL_PICTURE_FULFILLED = "UPDATE_TICKET_ARRIVAL_PICTURE_FULFILLED";

export const UPDATE_TICKET_ARRIVAL_GEO_REQUESTED = "UPDATE_TICKET_ARRIVAL_GEO_REQUESTED";
export const UPDATE_TICKET_ARRIVAL_GEO_REJECTED = "UPDATE_TICKET_ARRIVAL_GEO_REJECTED";
export const UPDATE_TICKET_ARRIVAL_GEO_FULFILLED = "UPDATE_TICKET_ARRIVAL_GEO_FULFILLED";

export const CLOSE_NEW_TICKET_REQUESTED = "CLOSE_NEW_TICKET_REQUESTED";
export const CLOSE_NEW_TICKET_REJECTED = "CLOSE_NEW_TICKET_REJECTED";
export const CLOSE_NEW_TICKET_FULFILLED = "CLOSE_NEW_TICKET_FULFILLED";

export const GET_NEXT_TICKET_NUMBER_REQUESTED = "GET_NEXT_TICKET_NUMBER_REQUESTED";
export const GET_NEXT_TICKET_NUMBER_REJECTED = "GET_NEXT_TICKET_NUMBER_REJECTED";
export const GET_NEXT_TICKET_NUMBER_FULFILLED = "GET_NEXT_TICKET_NUMBER_FULFILLED";

export const GET_STRIPE_CUSTOMER_REQUESTED = "GET_STRIPE_CUSTOMER_REQUESTED";
export const GET_STRIPE_CUSTOMER_REJECTED = "GET_STRIPE_CUSTOMER_REJECTED";
export const GET_STRIPE_CUSTOMER_FULFILLED = "GET_STRIPE_CUSTOMER_FULFILLED";

export const GET_STRIPE_PAYMENT_METHOD_REQUESTED = "GET_STRIPE_PAYMENT_METHOD_REQUESTED";
export const GET_STRIPE_PAYMENT_METHOD_REJECTED = "GET_STRIPE_PAYMENT_METHOD_REJECTED";
export const GET_STRIPE_PAYMENT_METHOD_FULFILLED = "GET_STRIPE_PAYMENT_METHOD_FULFILLED";

export const GET_USER_REQUESTED = "GET_USER_REQUESTED";
export const GET_USER_REJECTED = "GET_USER_REJECTED";
export const GET_USER_FULFILLED = "GET_USER_FULFILLED";

export const GET_PARTNER_SITES_REQUESTED = "GET_PARTNER_SITES_REQUESTED";
export const GET_PARTNER_SITES_REJECTED = "GET_PARTNER_SITES_REJECTED";
export const GET_PARTNER_SITES_FULFILLED = "GET_PARTNER_SITES_FULFILLED";

export const MARK_TICKET_AS_IN_PROGRESS ="MARK_TICKET_AS_IN_PROGRESS";
export const SET_TICKET_FOR_MODAL ="SET_TICKET_FOR_MODAL";