import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
//import App from './App';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// Components
import Login        from './components/Login';
import Register     from './components/Register';
import VerifyRegister     from './components/VerifyRegister';
import Logout        from './components/Logout';
import Amplify, { Auth } from 'aws-amplify';
import config from './aws-exports';
import App from './App';
//import { ConfirmationProvider } from './context/Confirmation';
import jwtDecode from 'jwt-decode';
import storeConfig from './store';
import {
  UTILS
} from './constants/types';
const { store, persistor } = storeConfig();

const loader = () => {
return (
<div open={true}>
  loading
</div>
)
}
Amplify.configure(config);

Auth.currentAuthenticatedUser()
  .then(user => {
    const userJWT = user.signInUserSession.idToken.jwtToken;

    if(userJWT!==null){
      //const userDecoded = jwtDecode(userJWT);
      localStorage.setItem('token', userJWT);
    }
    const token = localStorage.getItem('token');
    const userDecoded = jwtDecode(token);
    console.log('userDecoded',userDecoded);
    if (token) {
      store.dispatch({ type: UTILS.SET_USER_ID, userId: userDecoded['cognito:username'] })
      store.dispatch({ type: UTILS.SET_USER_EMAIL, userEmail: userDecoded['email'] })
    store.dispatch({ type: UTILS.SET_TOKEN, tokenId:token })
    }

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={loader} persistor={persistor}>


          <App />


    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

  })
  .catch(err => {
    ReactDOM.render(
      <Provider store={store}>
        <PersistGate loading={loader} persistor={persistor}>

          <BrowserRouter>
            <Switch>
              <Route path="/login"  component={ Login } />
              <Route path="/register"  component={ Register } />
              <Route path="/account-confirmation"  component={ VerifyRegister } />
              <Route path="/logout"  component={ Logout } />
              <Redirect from="*" to="/login" />
            </Switch>
          </BrowserRouter>

        </PersistGate>
      </Provider>,
      document.getElementById('root')
    );


  });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
