import React, {Fragment, Component} from 'react';
import {assuredURL} from '../../constants';
import jwtDecode from 'jwt-decode';
import ModalEditTeam from '../Home/ModalEditTeam'

export default class SiteDisplayEmpty extends Component {

    constructor(props){
    	super(props);

    	const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);

    	this.state = {
    		userJWT
    	};
	}


	link = (pageURL) => {
		let {site} = this.props;
		let {userJWT} = this.state;

		return `${assuredURL}/auth?url=${pageURL}&site=${site.idsite}&token=${userJWT}`;

	}

	render(){
		let {site, refreshSites} = this.props;
		//let {name, idsite, team_size} = site;
		return(<Fragment>
			
			<div class="card-body">

				{site.idprofile == 1 || site.idprofile == 2 ?
					<div class="col-12 text-center">     
						<a class="btn btn-primary mt-4" href={this.link("disposal-agreement-setup-1")}>+ Add a Delivery Agreement</a>
					</div>
				: null }

				{site.team_size == 1 ?
					<div class="col-12 text-center">
						<button class="btn btn-outline-primary mt-4 " data-toggle="modal" data-target={"#edit-team-"+site.idsite}>Start your Site Team</button>
					</div>
				:
					<div class="col-12 pt-6 px-0 clearfix">     
                      <a class="link small strong text-primary cursor float-right" data-toggle="modal" data-target={"#edit-team-"+site.idsite}>
							<u>{site.idprofile == 3 || site.idprofile == 4 ? "View" : "Manage" } Site Team</u>
						</a>
                    </div>  }

			</div>

			<ModalEditTeam idsite={site.idsite} name={site.name} refreshSites={refreshSites} />

		</Fragment>)
	}

}