import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch} from "react-redux";
import _ from 'lodash';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import Dialog from '../common/Dialog';
import { createSite, getSitesByUser } from '../../actions/Sites';
import { setSiteName, setAssuredSiteId, setAssuredProfileId, setHaulSiteId, setHaulSetup, } from '../../actions/Utils';
import NewSite from './newSite';
import './homepage.css'

export default function MapContainer(props) {
  const { username, locked } = props;
  const dispatch = useDispatch();

  const [mapInstance, setMapInstance] = useState(null);
  const [zoom, setZoom] = useState(13);

  const defaultCenter = {
    lat: 43.038902, lng: -87.906471,
  }

  const [showTempMarker, setShowTempMarker] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showInfoWindow, setOpenInfoWindow] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [user] = useState(username);
  const [selectedSite,setSelectedSite] = useState('');
  const [location, setLocation] = useState(
        defaultCenter
      );
      const statusSitesByUser = useSelector(state => state.Sites.getSitesByUser.success);
      const sitesByUser = useSelector(state => state.Sites.getSitesByUser);
      const arraySites = (sitesByUser.inProgress === false && sitesByUser.success === true &&  _.isEmpty(sitesByUser) === false) ? sitesByUser.data.sitesList : []
     const userId = useSelector(state => state.Utils.userId)
     const userEmail = useSelector(state => state.Utils.userEmail)
     useEffect(() => {
       if (_.isEmpty(sitesByUser.data)) {
         dispatch(getSitesByUser(userId, userEmail));
       }
     },[userId, statusSitesByUser])
  /*const mapStyles = {
    minHeight: '400px',
    width: "100%"
  };*/


    const onLoad = marker => {
      //console.log('marker: ', marker)
    }

  const CloseDialog = () => {
    //setOpenInfoWindow(false);
    setOpenDialog(false);
    setShowTempMarker(false);
  }
  const OpenDialog = () => {
  setOpenDialog(true);
  }

  const closeInfoWindow = () => {
    setOpenInfoWindow(false);
  }

  const onClickMap = (e) => {
    console.log("onClickMap", "Map locked?", locked);
    if (!locked){
      console.log("onClickMap")
      console.log(e.latLng);
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      //OpenDialog()
      setLocation({lat,lng})
      setShowTempMarker(true);

      setTimeout(()=>{
        OpenDialog()
      }, 500);

      console.log('User',user);
      //console.log('OK',lat,lng);
    }
  }


  const locations = [
  { lat: 43.034435259916016, lng: -87.91000722541297 },
];

const onSelect = (site) => {
  setSelectedSite(site);
  setOpenInfoWindow(true);
  dispatch(setAssuredSiteId(site.idsite));
  dispatch(setHaulSiteId(site.haulSiteId));
  dispatch(setSiteName(site.name));
  dispatch(setAssuredProfileId(site.idprofile));
  dispatch(setHaulSetup(site.haulSetup));
  let lat = site.location_x;
  let lng = site.location_y;
  //setLocation({lat, lng})
  console.log('Itemm -->',site);
}

  const markerSize = () => {

    let baseWidth = 27;
    let baseHeight = 43;

    if (zoom == 13) {
      return {width: baseWidth, height: baseHeight};
    } else {
      let factor = 1;
      if (zoom > 13){
        factor = (zoom - 13) * 1.1;
      } else if (zoom < 13){
        factor = (1 / ((13 - zoom) * 2));
      }

      let width = baseWidth * factor;
      let height = baseHeight * factor;

      if (width > (baseWidth * 1.5))
        width = baseWidth * 1.5;
      if (height > (baseHeight * 1.5))
        height = baseHeight * 1.5;

      return { width, height }
    }



  }


  return (

       <LoadScript
         googleMapsApiKey='AIzaSyCRTgaqtoxDv1Uj23wnjgFG249oqCJ4uw4'>
          <GoogleMap
            onLoad={map => setMapInstance(map)}
            mapContainerClassName="map-container"
            zoom={13}
            onZoomChanged={() => {if (mapInstance) { setZoom(mapInstance.zoom)}}}
            center={location || defaultCenter}
            onClick={onClickMap}
          >
          <Dialog
            openDialog={openDialog}
            onCloseDialog={CloseDialog}
            modalTitle={'New Site'}
            width={'sm'}
            buttonText={'Close'}
          >
            <div style={{width: '100%'}}>
              <NewSite
              location={location}
              user={user}
              onCloseDialog={CloseDialog}
              setSiteRefresh={ (newSite) => {
                //console.log("setSiteRefresh", "newSite", newSite);
                //console.log("setSiteRefresh", "selectedSite:", selectedSite)
                //console.log("setSiteRefresh", "arraySites", arraySites);

                let newSelectedSite = {
                  name: newSite.projectName,
                  location_y: newSite.newSite.longitud,
                  location_x: newSite.newSite.latitud,
                  haulSiteId: newSite.haulSiteId,
                  idsite: newSite.assuredSiteId,

                  haulSetup: false,
                  idprofile: 1,
                  tickets: 0,
                  tons_dumped: 0,
                  projects: 0,
                }

                setSelectedSite(newSelectedSite);
                setOpenInfoWindow(true);
                //console.log("setSiteRefresh", "newSelectedSite", newSelectedSite);
                //console.log("setSiteRefresh", "selectedSite updated:", selectedSite)
                props.setSiteRefresh(true);
              }}
              />
            </div>
          </Dialog>
              {
              arraySites.map( position => {
                let renderMarker = false;
                let coordinates = '';
                let siteName = '';
                // console.log('arraySites',arraySites.sitesList);position.location_x !== 0 &&
                if(position.location_x !== 0 && position.location_y !== 0){
                  siteName = position.name;
                  coordinates =  { lat: position.location_x, lng: position.location_y };
                  renderMarker =
                    <Marker onLoad={onLoad}
                      icon={{
                          url: "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png",
                          scaledSize: markerSize()
                        }}
                      key={position.idsite}
                      position={coordinates}
                      clickable={true}
                      siteName={position.name}
                      siteId={position.idsite}
                      onClick={(props, marker, e) => onSelect(position, marker)}/>
                }
                return renderMarker
              })
              }

             {showInfoWindow ? <InfoWindow
                visible={showInfoWindow}
                onCloseClick={closeInfoWindow}
                position={{lat: selectedSite.location_x + 0.005, lng: selectedSite.location_y}}>
                <div>
                  {selectedSite.name}
                </div>
              </InfoWindow> : null}

            {showTempMarker ? <Marker
                icon={{
                  url: "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png",
                  scaledSize: markerSize()
                }}
                position={location}
                clickable={false}
            /> : null }

          </GoogleMap>
       </LoadScript>
  )
}
