import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createSite, getSitesByUser } from '../../actions/Sites';
import { setSiteName, setAssuredSiteId, setAssuredProfileId, setHaulSiteId, } from '../../actions/Utils';
import ajax from 'superagent';
import { apiURl } from '../../constants';
import moment from 'moment';


export default function NewSite(props) {
  const statusSite = useSelector(state => state.Sites.createSite.success);
  const userId = useSelector(state => state.Utils.userId);
  const userEmail = useSelector(state => state.Utils.userEmail)
  const dataSite = useSelector(state => state.Sites.createSite);
//  const dataSiteTeam = useSelector(state => state.Sites.createSite.data);
  const newDataSite = (dataSite.inProgress === false && dataSite.success === true && _.isEmpty(dataSite.data) === false) ?
  dataSite.data : [];
  //const newDataTeam = (dataSiteTeam.inProgress === false && dataSiteTeam.success === true && _.isEmpty(dataSiteTeam.data) === false) ?
  //    true: false;
  const {onCloseDialog, location}= props;
  const [geometry] = useState(location);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      nameProject: '',
      open_time: '',
      close_time: ''
    },
    validationSchema: Yup.object({
      nameProject: Yup.string()
        .max(25, 'Must be 25 characters or less')
        .required("Name of project cann't be empty"),
    }),
    onSubmit: values => {
      //alert(JSON.stringify(values, null, 2));
      updateSiteHandler(values)
    },
  });




const updateSiteHandler = async (formValues) => {
  const data = {formValues,userId,geometry}
  //let site = await dispatch(createSite(data));

  ajax.post(`${apiURl}/sites/add`)
      .send(data)
      .end(async (error, response) => {
          if (!error && response) {
              await dispatch(getSitesByUser(userId, userEmail));
              dispatch(setSiteName(formValues.nameProject));
              dispatch(setAssuredSiteId(response.body.assuredSiteId));
              dispatch(setHaulSiteId(response.body.haulSiteId));
              dispatch(setAssuredProfileId("1"));
              props.setSiteRefresh(response.body);
              onCloseDialog(false);
          } else {
              //console.log('There was an error', error);
          }
      }
  );
}

const validateTime = (time) =>{
  moment(time, [
    "Hmm", "HHmm",
    "h:mm a", "h:mm A", "h:mma", "h:mmA",
    "h:m a", "h:m A", "h:ma", "h:mA",
    "hh:mm a", "hh:mm A", "hh:mma", "hh:mmA",
    "hh:mm a", "hh:mm A", "hh:mma", "hh:mmA", 
    
  ])
}


  return (

    <form onSubmit={formik.handleSubmit}>
      <div className="modal-dialog modal" role="document">
        <div className="modal-content">
          <div className="modal-body pt-0">
            <div className="row m-0 mb-5">
              <div className="col-lg-12">
                <div className="form-group row">
                  <div className="col-12 px-0 mb-2 mx-auto">
                    <label className="lead text-dark fw-600">Site Name</label>
                      <div className="contrast">
                        <input
                        className="form-control"
                        id="nameProject"
                        name="nameProject"
                        type="text"
                        placeholder="Name of Project"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.nameProject}
                        />
                        {formik.touched.nameProject && formik.errors.nameProject ? (
                        <div className="error-message">{formik.errors.nameProject}</div>
                        ) : null}
                        </div>
                        <button type="submit" className="btn btn-lg btn-primary mt-1" >Create Site</button>
                      </div>
                  </div>
                </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  );
};
