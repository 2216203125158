import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Switch, Route, withRouter, Redirect } from 'react-router-dom';
import Header from './components/common/Navbar';

import Login from './components/Login';
import Register from './components/Register';
import VerifyRegister from './components/VerifyRegister';
import Logout from './components/Logout';
import PrivateRoute from './components/common/PrivateRoute';
import UserSettings from './components/UserSettings'
import Index        from './components/Home';
// TODO: Test RequireAuth HOC
// import RequireAuth from './components/RequireAuth';

import * as routes from './constants/routes';

class Routes extends Component {

  render() {
    const { location } = this.props;
    let header = (<div></div>);
		switch(location.pathname) {
			case '/settings':
      break;
			case '/':
				header = (<Header />);
				break;
			case '/map':
				header = (<Header />);
				break;
			case '/reports':
				header = (<Header />);
        break;
      case '/upload':
				header = (<Header />);
        break;
      case '/index':
        header = (<Header />);
        break;
      case '/haulcheck':
        break;
      case '/logout':
        break;
			default:

				break;
		}
    return (
      <Switch>
        <Route
          exact
          path={routes.LOGIN}
          component={Login}
        />
        <Route
          exact
          path={routes.REGISTER}
          component={Register}
        />
        <Route
          exact
          path={routes.VALIDATE}
          component={VerifyRegister}
        />
        <Route
          exact
          path={routes.LOGOUT}
          component={Logout}
        />
        <Route path={routes.HAULCHECK} component={() => {
        window.open('https://haulcheck-dev.earthideal.com/haul-checks','_self');
        return null;
        }}/>
        <PrivateRoute exact path={routes.HOME}>
          <Index />
        </PrivateRoute>
        <PrivateRoute exact path={routes.INDEX}>
          <Index />
        </PrivateRoute>
        <PrivateRoute exact path={routes.USER_SETTINGS}>
          <UserSettings />
        </PrivateRoute>
        <Redirect from="/" to={routes.HOME} />

      </Switch>
    );
  }
}

Routes.propTypes = {
  location: PropTypes.object.isRequired,
};


export default withRouter(Routes);
