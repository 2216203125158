import request from 'superagent';
import { SITES } from '../constants/types';
//import { apiURl } from '../constants/api';
import { apiURl } from '../constants'

export function createSite(data) {
  return dispatch => {
    dispatch(createSiteRequested());
    request
    .post(`${apiURl}/sites/add`)
    .set('Content-Type', 'application/json')
    .send(data)
    .then(response => {
      dispatch(createSiteFulfilled(response.body))
    })
    .catch((error) => {
      dispatch(createSiteRejected(error));
    });
  };
}

function createSiteRequested() {
  return {
    type: SITES.CREATE_NEW_SITE_REQUESTED,
    inProgress: true,
    success: false,
    error: false,
    data: []
  };
}

function createSiteRejected(error) {
  return {
    type: SITES.CREATE_NEW_SITE_REJECTED,
    inProgress: false,
    success: false,
    error,
    data: []
  };
}

function createSiteFulfilled(data) {
  return {
    type: SITES.CREATE_NEW_SITE_FULFILLED,
    inProgress: false,
    success: true,
    error: false,
    data
  };
}

export function createSiteTeam(data) {
  return dispatch => {
    dispatch(createSiteTeamRequested());
    request
    .post(`${apiURl}/sites/addSiteUsers`)
    .set('Content-Type', 'application/json')
    .send(data)
    .then(response => {
      dispatch(createSiteTeamFulfilled(response.body))
    })
    .catch((error) => {
      dispatch(createSiteTeamRejected(error));
    });
  };
}

function createSiteTeamRequested() {
  return {
    type: SITES.CREATE_SITE_TEAM_REQUESTED,
    inProgress: true,
    success: false,
    error: false,
    data: []
  };
}

function createSiteTeamRejected(error) {
  return {
    type: SITES.CREATE_SITE_TEAM_REJECTED,
    inProgress: false,
    success: false,
    error,
    data: []
  };
}

function createSiteTeamFulfilled(data) {
  return {
    type: SITES.CREATE_SITE_TEAM_FULFILLED,
    inProgress: false,
    success: true,
    error: false,
    data
  };
}
export function getSitesByUser(email, id) {
	return dispatch => {
    dispatch(getSitesByUserRequestedAction());
    request
    .get(`${apiURl}/sites/readSiteAndProjectDataByUserId/${email}/${id}`)
    .set('Accept', 'application/json')
    .then(response => {
      dispatch(getSitesByUserFulfilledAction(response.body))
    })
    .catch((error) => {
      dispatch(getSitesByUserRejectedAction(error));
    });
  }
}

function getSitesByUserRequestedAction() {
  return {
    type: SITES.GET_SITES_BY_USER_REQUESTED,
		inProgress: true,
		success: false,
		error: false,
		data: []
  };
}

function getSitesByUserRejectedAction(error) {
  return {
    type: SITES.GET_SITES_BY_USER_REJECTED,
    inProgress: false,
    success: false,
    error,
		data: []
  };
}

function getSitesByUserFulfilledAction(data) {
  return {
    type: SITES.GET_SITES_BY_USER_FULFILLED,
    data,
    success: true,
    inProgress: false,
    error: false
  };
}
