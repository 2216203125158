import React, {Component} from 'react';
//import { Spinner } from 'react-bootstrap';
import ajax, {Request} from 'superagent';
import {CardElement, CardNumberElement, CardExpiryElement, CardCvcElement, injectStripe} from 'react-stripe-elements';
import { connect } from 'react-redux';
import {url as config, assuredAPIURL} from '../../constants';
import { setStripeToken, setStripeId } from "../../services/userService";
import { getUser } from '../../actions/user';
import { getStripePaymentMethod, getStripeCustomer } from '../../actions/stripe';
import jwtDecode from 'jwt-decode';

import "./style.css";

class BillingInfo extends Component {
    constructor(props){
        super(props);

        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);

        this.state = {
            custName: '',
            company: '',
            line1: '',
            line2: '',
            stateName: '',
            zip: '',
            cardnumber: '',
            cardtype: '',
            cardexp: '',
            ccv: '',
            stripePayment: null,
            showLoadingWidget: false,
            disbledCardSubmitButton: false,
            userId: null,
            userDecoded
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount = () =>{
        ajax.post(`${assuredAPIURL}/users/auth`)
            .send(this.state.userDecoded)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({
                        userId: response.body.user.iduser, 
                        showLoadingWidget: false, 
                        disbledCardSubmitButton: false
                    }, () => {
                        this.getPaymentMethod(response.body.user.stripe_token);
                    });
                } else {
                    console.log('There was an error', error);
                }
            }
        );
    }



   getPaymentMethod = (token) => {

    console.log("get payment method called");

        ajax.get(`${assuredAPIURL}/stripe/payment/${token}`)
            .end((error, response) => {
                if (!error && response) {
                    console.log("stripe payment response", response);
                    //this.setState({profilesList: validProfiles})
                    this.setState({stripePayment: response.body.payment, showLoadingWidget: false, disbledCardSubmitButton: false});
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }


    async createUser() {
        this.setState({disbledCardSubmitButton: true})
        // Replace by stripe.createPaymentMethod
        let token = await this.props.stripe.createPaymentMethod('card');
        console.log("token", token);
        this.setState({showLoadingWidget: true});
        ajax.get(`${assuredAPIURL}/users/id/${this.state.userId}`)
        .end((error, response) => {
            if (!error && response) {
                if (token.error){
                    alert("Error! " + token.error.message);
                    return;
                } else {
                    const data = {
                        source : {
                            object: 'card',
                            payment_method: token.paymentMethod.id,
                            name: this.state.custName,
                            company: this.state.company,
                            address_line1: this.state.line1,
                            address_line2: this.state.line2,
                            address_state: this.state.stateName,
                            address_zip: this.state.zip,
                            email: response.body.user[0].email,
                        },
                    }
                    ajax.post(`${assuredAPIURL}/stripe/customer/`)
                    .send(data)
                    .then(res => {
                        setStripeToken(this.state.userId, token.paymentMethod.id);
                        setStripeId(this.state.userId, res.body.customer.id);
                        this.props.getStripePaymentMethod(token.paymentMethod.id);
                        this.getPaymentMethod(token.paymentMethod.id);
                    }).catch(e => {
                        throw e;
                    });
                }
            } else {
                console.log('There was an error fetching the user: ', error);
            }
        });
    }

    handleChange(e){
        this.setState({
            [e.target.name]: e.target.value,
        })
    }
    render(){
        if(this.state.showLoadingWidget){
            return (
                <div className="tab-pane fade" id="billing">
                    <h2 className="text-center">Billing Info</h2>
                    <div className="loader">Loading...</div>
                </div>
            )
        }else if(this.state.stripePayment){
            console.log('el showWidget es falso y sí hay medio de pago registrado', this.state.stripePayment)
            return (
                <React.Fragment>
                    <h2 className="text-center">Billing Info</h2>
                    <p className="text-center mb-5">You have registered a card with this information. </p>
                    <div className="card border">
                        <div className="card-body bg-gray">
                            <div className="form-group row gap-y">
                                <label className="col-12 pb-1">Card type: {this.state.stripePayment.card.brand}</label>
                                <label className="col-12 pb-1">Card number: **{this.state.stripePayment.card.last4}</label>
                            </div>
                            <div className="form-group row gap-y mt-4">
                                <div className="col-md-6">
                                    <br />
                                    <button id="change-card" type="button" className="btn btn-primary" onClick={() => {this.setState({stripePayment: null})}}>Change Card</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }else{

            return(<React.Fragment>

            <div className="loader" style={{display: this.state.disbledCardSubmitButton ? 'block' : 'none'}}>Loading...</div>

            <h2 className="text-center">Billing Info</h2>
                <div className="card border" style={{display: !this.state.disbledCardSubmitButton ? 'block' : 'none'}}>
                    <div className="card-body bg-gray">
                        <div className="form-group row gap-y">
                            <label className="col-12 pb-1">Billing Address</label>

                            <div className="col-12 col-md-8 col-lg-6 pt-0 pb-0 mr-12 mb-3">
                                <input className="form-control form-control-sm" name="custName" type="text" value={this.state.custName} onChange={this.handleChange} placeholder="Cardholder Name" />
                            </div>
                            <div className="col-12 col-md-8 col-lg-6 pt-0 pb-0 mr-12 mb-3">
                                <input className="form-control form-control-sm" name="company" type="text" value={this.state.company} onChange={this.handleChange} placeholder="Company Name" />
                            </div>
                            <div className="col-12 col-md-8 col-lg-6 pt-0 pb-0 mr-12 mb-3">
                                <input className="form-control form-control-sm" name="line1" type="text" value={this.state.line1} onChange={this.handleChange} placeholder="Address Line 1" />
                            </div>
                            <div className="col-12 col-md-8 col-lg-6 pt-0 pb-0 mr-12 mb-3">
                                <input className="form-control form-control-sm" name="line2" type="text" value={this.state.line2} onChange={this.handleChange} placeholder="Address Line 2" />
                            </div>
                            <div className="col-12 col-md-8 col-lg-3 pt-0 pb-0 mb-3" placeholder="State">
                                <select name="stateName" className="form-control form-control-sm" onChange={this.handleChange}>
                                    <option>State</option>
                                    <option>AK</option>
                                    <option>AL</option>
                                    <option>AR</option>
                                    <option>AZ</option>
                                    <option>CA</option>
                                    <option>CO</option>
                                    <option>CT</option>
                                    <option>DE</option>
                                    <option>FL</option>
                                    <option>GA</option>
                                    <option>HI</option>
                                    <option>IA</option>
                                    <option>ID</option>
                                    <option>IL</option>
                                    <option>IN</option>
                                    <option>KS</option>
                                    <option>KY</option>
                                    <option>LA</option>
                                    <option>MA</option>
                                    <option>MD</option>
                                    <option>ME</option>
                                    <option>MI</option>
                                    <option>MN</option>
                                    <option>MO</option>
                                    <option>MS</option>
                                    <option>MT</option>
                                    <option>NC</option>
                                    <option>ND</option>
                                    <option>NE</option>
                                    <option>NH</option>
                                    <option>NJ</option>
                                    <option>NM</option>
                                    <option>NV</option>
                                    <option>NY</option>
                                    <option>OH</option>
                                    <option>OK</option>
                                    <option>OR</option>
                                    <option>PA</option>
                                    <option>RI</option>
                                    <option>SC</option>
                                    <option>SD</option>
                                    <option>TN</option>
                                    <option>TX</option>
                                    <option>UT</option>
                                    <option>VA</option>
                                    <option>VT</option>
                                    <option>WA</option>
                                    <option>WI</option>
                                    <option>WV</option>
                                    <option>WY</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-8 col-lg-3 pt-0 pb-0 mb-3">
                                <input className="form-control form-control-sm" type="text" placeholder="Zip Code" />
                            </div>
                        </div>
                        <div className="form-group row gap-y mt-4">
                            <label className="col-12 pb-1">Credit Card</label>

                            <div className="col-12 col-md-8 col-lg-6 pt-0 pb-0 mr-12 mb-3">
                                <CardNumberElement className="form-control form-control-sm force-font" type="text" placeholder="Card Number" />
                            </div>
                            {/*<div className="col-12 col-md-8 col-lg-3 pt-0 pb-0 mb-3">
                                <select className="form-control form-control-sm">
                                    <option>Card Type</option>
                                    <option>VISA</option>
                                    <option>MasterCard</option>
                                    <option>Discovery</option>
                                    <option>American Express</option>
                                </select>
                            </div>*/}
                            <div className="col-12 col-md-8 col-lg-3 pt-0 pb-0 mb-3">
                                <CardExpiryElement className="form-control form-control-sm force-font" type="text" placeholder="Exp Date (mm/yy)" />
                            </div>
                            <div className="col-12 col-md-8 col-lg-3 pt-0 pb-0 mr-12 mb-3">
                                <CardCvcElement className="form-control form-control-sm force-font" type="text" placeholder="Security Code" />
                            </div>
                            <div className="col-md-6">
                                <div data-provide="recaptcha" data-enable="#submit-btn"></div>
                                <br />
                                <button id="submit-btn" className="btn btn-primary" type="submit" disabled={this.state.disbledCardSubmitButton} onClick={this.createUser.bind(this)}>Submit</button>

                            </div>
                        </div>


                    </div>
                </div> 
            </React.Fragment>)
        }
    }
}
function mapStateToProps(state) {
    return {
        //user: state.user,
        stripeData: state.stripe
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //getUser: (id) => dispatch(getUser(id)),
        getStripePaymentMethod: (id) => dispatch(getStripePaymentMethod(id)),
        getStripeCustomer: (id) => dispatch(getStripeCustomer(id)),
    };
}
export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(BillingInfo));

