import React, {Fragment, Component} from 'react';
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import UnlockMapButton from './UnlockMapButton';
import SiteDisplay from './SiteDisplay';
import { assuredURL, assuredAPIURL } from '../../constants'

export default class Sidebar extends Component {

    constructor(props){
    	//Accepted props include a list of sites

    	const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        let username = userDecoded['cognito:username'];
        let email = userDecoded['email'];

    	super(props)
    	this.state={
    		sites: [],
    		username,
        	userJWT,
    		email
    	}
	}


	componentWillMount = async () => {
		this.getSiteList();
	}

  componentWillReceiveProps = (newProps) => {
    let {siteRefresh, setSiteRefresh} = newProps;
    if (siteRefresh){
      this.getSiteList(true);
      setSiteRefresh(false);
    } 
  }

  link = (pageURL) => {
    let {userJWT} = this.state;
    return `${assuredURL}/auth?url=${pageURL}&token=${userJWT}`;

  }

  getSiteList = (openNewest) => {
    let {email, username} = this.state;
    ajax.get(`${assuredAPIURL}/sites/readSiteAndProjectDataByUsername/${username}`)
      .end((error, response) => {
        if (!error && response) {
          let sites = response.body.sitesList;
          console.log("Sidebar", "Sites response", response);
          this.setState({sites});

          //If needbe, open a specific sidebar accordion
          if (openNewest){
            setTimeout(()=> {
              let idsite = sites[sites.length-1].idsite;
              console.log("12345", idsite);
              document.getElementById('collapse-trigger-'+idsite).click();
            }, 500);
          }
        } else {
          console.log('There was an error fetching', error);
        }
      }
    );
  }

	render(){
		return(<Fragment>
      <div class="col-12 px-0 clearfix">
        <h4 class="mt-2 mb-2 ml-4 float-left">My Sites</h4>
        <a class="h6 pt-2 mt-2 mr-4 float-right" href={this.link("my-partners")}>My Partners<i class="fa fa-angle-right mx-2" aria-hidden="true"></i></a>
      </div>
      <hr class="mt-0 mb-4" />

			{this.renderSidebar()}
		</Fragment>)
	}

	renderEmptyState = () => {
		let {mapLocked, setMapLocked} = this.props;
		return (<Fragment>

			{/*<div className="alert alert-primary alert-dismissible fade show" role="alert">
				<strong>Welcome to Assured Disposal!</strong><br /> If you ever need help, look for the <i className="fa fa-question-circle" aria-hidden="true"></i> icon or check the main <b>Support Menu</b>.
				<button type="button" className="close" data-dismiss="alert" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>*/}

			<UnlockMapButton 
				className="btn btn-block btn-primary mt-4"
				mapLocked={mapLocked}
				setMapLocked={setMapLocked}>
				+ Add Your First Site
			</UnlockMapButton>

			<hr className="mt-6" />

		</Fragment>)

	}

	renderSitesList = () => {
		let {mapLocked, setMapLocked} = this.props;
		let {sites} = this.state;

		return(<Fragment>

			{sites.map((site, key)=>{
				return <SiteDisplay site={site} key={key} refreshSites={this.getSiteList} />
			})}

			<UnlockMapButton 
				className="btn btn-sm btn-outline-primary mt-4 pb-3"
				mapLocked={mapLocked}
				setMapLocked={setMapLocked}>
				+ Add a Site
			</UnlockMapButton>

		</Fragment>)
	}

	renderSidebar = () => {
		//alert("Calling renderSitesList")
		let {sites} = this.state;

		if (!sites || sites.length == 0){
			return this.renderEmptyState();
		} else {
			return this.renderSitesList();
		}
	}

}
