import React, {Fragment, Component} from 'react';
import SiteDisplayEmpty from './SiteDisplayEmpty';
import SiteDisplayAgreements from './SiteDisplayAgreements';

export default class SiteDisplay extends Component {

    constructor(props){
    	super(props);

    	this.state = {};
	}


	render(){
		let {site, refreshSites} = this.props;
		let {name, idsite, projects} = site;
		return(<Fragment>

			<div class="accordion accordion-arrow-right" id={"accordion-"+idsite}>
				<div class="card">
					<p class="card-title lead fw-700">
                      <a class="collapsed" id={"collapse-trigger-"+idsite} data-toggle="collapse"  href={"#collapse-"+idsite+"-"+idsite}>{name}</a>
                    </p>

					<div id={"collapse-"+idsite+"-"+idsite} class="collapse" data-parent={"#accordion-"+idsite}>
	                    <div class="card-body pt-0">

							{projects == 0 ? 
								<SiteDisplayEmpty site={site} refreshSites={refreshSites} />
							:
								<SiteDisplayAgreements site={site} refreshSites={refreshSites} />
							}
						</div>
					</div>
				</div>

          </div>
		</Fragment>)
	}

}