import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// switch to localforage after setting up persist-redux
import localforage from 'localforage';
// import storage from 'redux-persist/lib/storage'
import Transactions from './TransactionsReducer';
import Sites from './SitesReducer';
import Utils from './UtilsReducer';


const utilsPersistConfig = {
  key: 'Utils',
  storage: localforage,
  whitelist: [],
  debug: true,
}

const rootReducer = combineReducers({
  Utils: persistReducer(utilsPersistConfig, Utils),
  Transactions,
  Sites,
});

export default rootReducer;
