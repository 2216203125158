import React, {Fragment, Component} from 'react';
import BaseGuideModal from '../Event/BaseGuideModal';

export default class SupportGuide extends Component {

    constructor(props){

  	super(props);

	  	this.state = {
	  		modals: [
	  			//Getting Set Up
	  			{
	  				id: "getting-set-up",
	  				pages: [
	  					{
							img: "../../img/guide/setup-1.png",
							title: <strong>Add a Site</strong>,
							text: "Add a Site to your My Sites map to get started. Plot the location and give it a name. All of your Sites will appear in the map and in a list."
				        },
				        {
							img: "../../img/guide/setup-2.png",
							title: <strong>Add an Agreement</strong>,
							text: "Add an Agreement to your site in order to connect with a Partner. This allows users at the export site to open tickets and have them closed by users at the import site.",
				        },
				        {
							img: "../../img/guide/setup-5.png",
							title: <strong>Invite My Partner</strong>,
							text: "During Agreement Setup, you will be asked to invite your Partner to collaborate on setting up the agreement. Your partner will complete the setup form and the two sites will be connected.",
				        },
				        {
							img: "../../img/guide/setup-6.png",
							title: <strong>Pricing</strong>,
							text: <Fragment><p>To start an Agreement, you will be required to have a credit card on file.</p><p>Initiating Partners will automatically be charged 25 cents per ticket closed on the Agreement. This gives initiating Partners access to Premium Features for viewing the ticket Archive and generating tonnage reports.</p><p>Invited Partners have the option to opt in to Premium Features during Agreement Setup.</p></Fragment>,
				        },

	  				],
	  			},

	  			//Site Based Field Use
	  			{
	  				id: "site-based-field-use",
	  				pages: [
	  					{
							title: <strong>Start and Edit a Site Team</strong>,
							text: "At any time, you can invite team members (coworkers or clients, for example) to join your Site Team and get access to open, close, and/or view tickets for that site’s agreements.",
							img: "../../img/guide/setup-4.png",
				        },
				        {
							title: <strong>Start and Edit a Site Team</strong>,
							text: "Assured Deliveries is designed to be used in the field, allowing you and your team to manage tickets for all of your site’s agreements, no matter if you're exporting, importing, or both.",
							img: "../../img/guide/tickets-3.png",
				        },
	  				],
	  			},

	  			{
	  				id: "site-based-field-use-team-member",
	  				pages: [
	  					{
							title: <strong>Site Teams</strong>,
							text: "As a member of a Site Team, you have access to all agreements connecting your site to partner sites. Your partner site also has a team of users who will be handling tickets on their end of the agreement. You can contact the most recent user on a site by tapping on their username in a ticket that has already been closed today.",
							img: "../../img/guide/setup-9.png",
				        },
				        {
							title: <strong>Start and Edit a Site Team</strong>,
							text: "Assured Deliveries is designed to be used in the field, allowing you and your team to manage tickets for all of your site’s agreements, no matter if you're exporting, importing, or both.",
							img: "../../img/guide/tickets-3.png",
				        },
	  				],
	  			},

	  			{
	  				id: "site-based-field-use-guest",
	  				pages: [
	  					{
							title: <strong>Site Teams</strong>,
							text: "As a member of a Site Team, you have access to all agreements connecting your site to partner sites. Your partner site also has a team of users who will be handling tickets on their end of the agreement. You can contact the most recent user on a site by tapping on their username in a ticket that has already been closed today.",
							img: "../../img/guide/setup-9.png",
				        },
				        {
							title: <strong>My Sites & Ticket Feed</strong>,
							text: "Assured Deliveries is designed to be used in the field, allowing the Site Team to manage tickets for all agreements connected to your site. The My Sites screen gives you an overview of all your sites, with access to view and track tickets using the Active Ticket Feed.",
							img: "../../img/guide/tickets-3.png",
				        },
				        {
							title: <strong>Ticket Process</strong>,
							text: "Tickets will be opened by users on the export site and closed by users on the import site. Then each ticket will move from the Active tab to Hauled Today and finally to the Archive.",
							img: "../../img/guide/tickets-4.png",
				        },
	  				],
	  			},

	  			//Working with Tickets
	  			{
	  				id: "working-with-tickets",
	  				pages: [
	  					{
							title: <strong>New Ticket Form</strong>,
							text: <Fragment><p>For each load, an export user will open a new ticket by adding a Truck and selecting the Soil Profile being hauled. The smart form will auto-fill the rest so all you have to do is click Start.</p><p>Each ticket has a set tonnage for the load being hauled. The default tonnage is 20.00, but it can easily be edited at either the export site or the import site. This value will be used in reports later.</p></Fragment>,
							img: "../../img/guide/tickets-2.png",
				        },
				        {
							title: <strong>Closing Tickets</strong>,
							text: "After the ticket is opened, both Site Teams will be able to see the ticket appear in their site’s Active Ticket Feed. An import user will close the ticket when the load is dumped at their site.",
							img: "../../img/guide/tickets-4.png",
				        },
				        {
							title: <strong>Invalid Tickets and Recovery</strong>,
							text: "If something doesn’t go as planned, Co-Leaders and Team Members can mark a ticket as Invalid with a note about the situation. Tickets can also be Recovered if the load ends up going through.",
							img: "../../img/guide/tickets-5.png",
				        },
	  				],
	  			},

	  			//Tracking Trucks
	  			{
	  				id: "tracking-trucks",
	  				pages: [
	  					{
	  						title: <strong>Entering Trucks</strong>,
							text: "Every ticket must have a truck assigned to it, so each day export users will add Trucks to the system with the first round of tickets to be in the queue for the rest of the day.",
							img: "../../img/guide/trucks-1.png",
	  					},
	  					{
	  						title: <strong>Truck Profiles</strong>,
							text: <Fragment><p>Truck profiles are established using a license plate number and a photo of the truck. Once a Truck has been added to the system, it can be reused at a later date and updated at any time.</p><p>Truck profiles have an optional field for Empty Weight (E.W.). This value will automatically be entered into the Tonnage Calculator so you can type in the Gross Weight and get the tonnage.</p></Fragment>,
							img: "../../img/guide/trucks-2.png",
	  					},
	  					{
	  						title: <strong>Temporary Truck ID</strong>,
							text: "If the license plate is not visible, say for users sitting in an excavator, there is an option to use a Temporary Truck ID until the truck can be identified at a later time.",
							img: "../../img/guide/trucks-3.png",
	  					},
	  					{
	  						title: <strong>Today’s Trucks</strong>,
							text: <Fragment><p>Once a ticket has been opened, the truck will not appear under Today’s Trucks, but it will be shown in the tally of trucks that are ‘Loaded’ and en route to the dump site.</p><p>Once a load has been dumped and the ticket closed by an import user, the truck will appear under Today’s Trucks as ‘Empty’ and will show an ETA based on the Travel Time entered at setup.</p></Fragment>,
							img: "../../img/guide/trucks-4.png",
	  					},
	  				],
	  			},

	  			//Tracking and Reports
	  			{
	  				id: "tracking-and-reports",
	  				pages: [
	  					{
	  						title: <strong>Loads Hauled Today</strong>,
	  						text: "As tickets are closed at the dump site, each Site Team will be able to track today's total loads and total tonnage hauled for their site, in real time, under the Hauled Today tab.",
	  						img: "../../img/guide/tonnage-3.png",
	  					},
	  					{
	  						title: <strong>Ticket Archive</strong>,
	  						text: "At midnight, tickets under Hauled Today will automatically be moved to the Archive. You can search and filter the Archive to query tickets based on various data points.",
	  						img: "../../img/guide/tonnage-4.png",
	  					},
	  					{
	  						title: <strong>Tonnage Reports</strong>,
	  						text: "Finally, you can download a Tonnage Report PDF of your query results. It will show your site’s name, the filters you applied, a list of the resulting tickets, and the total tonnage for your query.",
	  						img: "../../img/guide/tonnage-5.png",
	  					},
	  				],
	  			},

	  			//Completing Setup
	  			{
	  				id: "completing-setup-getting-started",
	  				pages: [
	  					{
	  						title: <strong>My Partners</strong>,
	  						text: "You’ve been invited to complete an Agreement with your Agreement Partner. This Agreement will connect you and your Site with your Partner and their site, enabling you to send and track haul tickets between the two sites.",
	  						img: "../../img/guide/setup-7.png",
	  					},
	  					{
	  						title: <strong>Complete an Agreement</strong>,
	  						text: "Select or add the site you want to connect to your Agreement, and add or edit the Materials for the agreement as needed. Your partner has done the rest, so once you click complete, your sites will be connected.",
	  						img: "../../img/guide/setup-8.png",
	  					},
	  					{
							img: "../../img/guide/setup-6.png",
							title: <strong>Pricing</strong>,
							text: <Fragment><p>To start an Agreement, you will be required to have a credit card on file.</p><p>Initiating Partners will automatically be charged 25 cents per ticket closed on the Agreement. This gives initiating Partners access to Premium Features for viewing the ticket Archive and generating tonnage reports.</p><p>Invited Partners have the option to opt in to Premium Features during Agreement Setup.</p></Fragment>,
				        },
	  				],
	  			},

	  			//How It Works
	  			{
	  				id: "how-it-works",
	  				pages: [
	  					{
	  						title: <strong>My Sites</strong>,
	  						text: "You’ve been added to a Site Team to help track haul tickets from your site to a Partner site using what is called an Agreement. You can track haul activity for all of your sites and their agreements from the My Sites map and list.",
	  						img: "../../img/guide/setup-9.png",
	  					},
	  					{
	  						title: <strong>My Partners</strong>,
	  						text: "You can view a list of the site leaders that you have Agreements with on the My Partners page. It shows you which sites you are connected with for each partner.",
	  						img: "../../img/guide/setup-10.png",
	  					},
	  					{
							img: "../../img/guide/setup-2.png",
							title: <strong>Add an Agreement</strong>,
							text: "Add an Agreement to your site in order to connect with a Partner. This allows users at the export site to open tickets and have them closed by users at the import site.",
				        },
	  				],
	  			},

	  			//Tracking Hauls
	  			{
	  				id: "tracking-hauls",
	  				pages: [
	  					{
	  						title: <strong>Loads Hauled Today</strong>,
	  						text: "As tickets are closed at the dump site, each Site Team will be able to track today's total loads and total tonnage hauled for their site, in real time, under the Hauled Today tab.",
	  						img: "../../img/guide/tonnage-3.png",	
	  					}

	  				],
	  			},

	  			//Trucks Tracking and Reports
	  			{
	  				id: "trucks-tracking-and-reports",
	  				pages: [
	  					{
	  						title: <strong>Trucks</strong>,
	  						text: "Every ticket must have a truck assigned to it. Truck profiles are established using a license plate number and a photo of the truck. If the license plate is not visible, there is an option to use a Temporary Truck ID until the truck can be identified at a later time.",
	  						img: "../../img/guide/trucks-3.png",	
	  					},
	  					{
	  						title: <strong>Loads Hauled Today</strong>,
	  						text: "As tickets are closed at the dump site, each Site Team will be able to track today's total loads and total tonnage hauled for their site, in real time, under the Hauled Today tab.",
	  						img: "../../img/guide/tonnage-3.png",	
	  					},
	  					{
	  						title: <strong>Ticket Archive</strong>,
	  						text: "At midnight, tickets under Hauled Today will automatically be moved to the Archive. You can search and filter the Archive to query tickets based on various data points.",
	  						img: "../../img/guide/tonnage-4.png",
	  					},
	  					{
	  						title: <strong>Tonnage Reports</strong>,
	  						text: "Finally, you can download a Tonnage Report PDF of your query results. It will show your site’s name, the filters you applied, a list of the resulting tickets, and the total tonnage for your query.",
	  						img: "../../img/guide/tonnage-5.png",
	  					},

	  				],
	  			},


	  		]
	  	}

    }

    render(){
    	return(<Fragment>
    		{ this.state.modals.map((modal, key) => {
    			return  <BaseGuideModal key={key} id={modal.id} markAsSeen={() => {return true}} pages={modal.pages} suppressOpen={true} />
    		}) }
		</Fragment>)
    }


}