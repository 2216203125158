export const AUTHENTICATION = {
  CREATE_USER_REQUESTED: 'CREATE_USER_REQUESTED',
  CREATE_USER_REJECTED: 'CREATE_USER_REJECTED',
  CREATE_USER_FULFILLED: 'CREATE_USER_FULFILLED',
  AUTH_USER_REQUESTED: 'AUTH_USER_REQUESTED',
  AUTH_USER_REJECTED: 'AUTH_USER_REJECTED',
  AUTH_USER_FULFILLED: 'AUTH_USER_FULFILLED',
  AUTH_USER: 'AUTH_USER',
  UNAUTH_USER: 'UNAUTH_USER',
  PASSWORD_RESET_REJECTED: 'PASSWORD_RESET_REJECTED',
  PASSWORD_RESET_REQUESTED: 'PASSWORD_RESET_REQUESTED',
  PASSWORD_RESET_FULFILLED: 'PASSWORD_RESET_FULFILLED',
  PASSWORD_UPDATE_REJECTED: 'PASSWORD_UPDATE_REJECTED',
  PASSWORD_UPDATE_REQUESTED: 'PASSWORD_UPDATE_REQUESTED',
  PASSWORD_UPDATE_FULFILLED: 'PASSWORD_UPDATE_FULFILLED'
}

export const UTILS = {
  IS_PANEL_OPEN: 'IS_PANEL_OPEN',
  SET_ASSURED_PROFILE_ID: 'SET_ASSURED_PROFILE_ID',
  SET_ASSURED_SITE_ID: 'SET_ASSURED_SITE_ID',
  SET_HAUL_SITE_ID: 'SET_HAUL_SITE_ID',
  SET_HAUL_SETUP: 'SET_HAUL_SETUP',
  SET_SITE_NAME: 'SET_SITE_NAME',
  SET_PANEL_ORIENTATION: 'SET_PANEL_ORIENTATION',
  SET_TRANSACTION_ID: 'SET_TRANSACTION_ID',
  SET_USER_ID: 'SET_USER_ID',
  SET_USER_EMAIL: 'SET_USER_EMAIL',
  SET_TOKEN: 'SET_TOKEN'
}

export const TRANSACTIONS = {
  GET_TRANSACTIONS_GROUPS_BY_USER_REQUESTED: 'GET_TRANSACTIONS_GROUPS_BY_USER_REQUESTED',
  GET_TRANSACTIONS_GROUPS_BY_USER_REJECTED: 'GET_TRANSACTIONS_GROUPS_BY_USER_REJECTED',
  GET_TRANSACTIONS_GROUPS_BY_USER_FULFILLED: 'GET_TRANSACTIONS_GROUPS_BY_USER_FULFILLED',
  GET_TRANSACTIONS_BY_USER_REQUESTED: 'GET_TRANSACTIONS_BY_USER_REQUESTED',
  GET_TRANSACTIONS_BY_USER_REJECTED: 'GET_TRANSACTIONS_BY_USER_REJECTED',
  GET_TRANSACTIONS_BY_USER_FULFILLED: 'GET_TRANSACTIONS_BY_USER_FULFILLED',
  GET_TRANSACTION_BY_ID_REQUESTED: 'GET_TRANSACTION_BY_ID_REQUESTED',
  GET_TRANSACTION_BY_ID_REJECTED: 'GET_TRANSACTION_BY_ID_REJECTED',
  GET_TRANSACTION_BY_ID_FULFILLED: 'GET_TRANSACTION_BY_ID_FULFILLED',
  GET_TRANSACTION_TERMS_BY_ID_REQUESTED:'GET_TRANSACTION_TERMS_BY_ID_REQUESTED',
  GET_TRANSACTION_TERMS_BY_ID_REJECTED:'GET_TRANSACTION_TERMS_BY_ID_REJECTED',
  GET_TRANSACTION_TERMS_BY_ID_FULFILLED:'GET_TRANSACTION_TERMS_BY_ID_FULFILLED',
  UPDATE_TERMS_BUILDER_REQUESTED: 'UPDATE_TERMS_BUILDER_REQUESTED',
  UPDATE_TERMS_BUILDER_REJECTED: 'UPDATE_TERMS_BUILDER_REJECTED',
  UPDATE_TERMS_BUILDER_FULFILLED: 'UPDATE_TERMS_BUILDER_FULFILLED'
}

export const SITES = {
  CREATE_NEW_SITE_REQUESTED: 'CREATE_NEW_SITE_REQUESTED',
  CREATE_NEW_SITE_REJECTED: 'CREATE_NEW_SITE_REJECTED',
  CREATE_NEW_SITE_FULFILLED: 'CREATE_NEW_SITE_FULFILLED',
  CREATE_SITE_TEAM_REQUESTED: 'CREATE_SITE_TEAM_REQUESTED',
  CREATE_SITE_TEAM_REJECTED: 'CREATE_SITE_TEAM_REJECTED',
  CREATE_SITE_TEAM_FULFILLED: 'CREATE_SITE_TEAM_FULFILLED',
  GET_SITES_BY_USER_REQUESTED: 'GET_SITES_BY_USER_REQUESTED',
  GET_SITES_BY_USER_REJECTED: 'GET_SITES_BY_USER_REJECTED',
  GET_SITES_BY_USER_FULFILLED: 'GET_SITES_BY_USER_FULFILLED',
}
