import React from 'react';
import './styles.css';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { SignIn } from "aws-amplify-react";
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { url } from '../../constants'

class CustomLogin extends SignIn {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isOpen: false,
      message: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this._validAuthStates = ["signIn", "signedIn", "signedOut", "signedUp"];
    Auth.currentSession()
    //.then(data => this.props.history.push("/haul-checks"))
    .then(data => window.location.href = `${url.frontend}/index`)
    .catch(err => console.log(err));
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleClose(event) {
    this.setState({message: '', isOpen: false});
  }

  handleStateChanged(authState) {
    console.log("On State changed");
    const {redirect} = this.state;
    if (authState==='signedIn' && !redirect) {
      //this.props._authState = 'signedIn';
      this.setState({redirect: true});
      console.log(redirect);
      //window.location.href = `${url.frontend}/haul-checks`
    } else if (authState==='signedOut') {
      //this.props._authState = 'signedIn';
      this.setState({redirect: false});
      console.log("From SignedOut || SignIn" + redirect);
    }
  }

  keyPress = (e) =>{
      if(e.keyCode == 13){
         this.handleSignIn()
      }
   }

  async handleSignIn() {
    try {
      if (this.state.username==='' || this.state.password==='') {
        this.setState({message: 'Username or Password cannot be empty', isOpen: true});
        return;
      }
      const user = await Auth.signIn(this.state.username, this.state.password);
      this.setState({message: 'User logged in successfully', isOpen: true});
      //this.props.history.push("/");
      window.location.href = `${url.frontend}/`;
    } catch (error) {
        this.setState({message: 'Error signing in: ' + error.message, isOpen: true});
    }
    //super.signIn().then(() => {
        // this.props.history.push("/haul-checks");
        // window.location.href = `${url.frontend}/haul-checks`;
    // });
  }

    showComponent(theme) {
        return (
            <div className="Login" style={{backgroundImage: `url(/img/bg/4.jpg)` }}>
            <Snackbar open={this.state.isOpen}
              autoHideDuration={2500}
              onClose={this.handleClose}
              message = {this.state.message}
            >
            </Snackbar>
                <div className="Login-content">
                    <div className="background">
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <div>
                                <img src="/img/logo/ei-logo.png" alt="logo" width="45%" className="mb-6"/>
                                <h5 className="login-title">Log in</h5>

                                <form name="form">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="username"
                                        label="Username or Email Address"
                                        name="username"
                                        autoComplete="username"
                                        value={this.state.username} onChange={this.handleChange}
                                        onKeyUp={this.keyPress}
                                        autoFocus
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        value={this.state.password} onChange={this.handleChange}
                                        onKeyUp={this.keyPress}
                                        autoComplete="current-password"
                                    />

                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox value="remember" color="primary" />}
                                                label="Remember me"
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Link variant="body2" tabIndex="0" onClick={() => super.changeState("forgotPassword")}>
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Button
                                        type="button"
                                        onClick={() => {this.handleSignIn()} }
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        Login
                                    </Button>
                                    <p style={{textAlign: 'center', marginTop: '1.5em'}}>Don't have an account? <Link variant="body2" tabIndex="0" onClick={() => /*super.changeState("signUp")*/ window.location.href = url.frontend + "/register"}>Register here</Link>
                                    </p>
                                </form>
                            </div>
                        </Container>
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(CustomLogin);
