import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="container">
          <div className="row gap-y">
            <div className="col-md-6">
              <div className="nav nav-left">

              </div>
            </div>
            <div class="col-md-6 text-right">
              <a class="nav-link" href="#"><small>Assured Deliveries by Earth Ideal LLC ©2020</small></a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
