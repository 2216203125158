import React, {Fragment, Component} from 'react';
import {assuredURL} from '../../constants';
import jwtDecode from 'jwt-decode';
import ModalEditTeam from '../Home/ModalEditTeam'

export default class SiteDisplayAgreements extends Component {

    constructor(props){
    	super(props);

    	const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);

    	this.state = {
    		userJWT
    	};
	}


	link = (pageURL) => {
		let {site} = this.props;
		let {userJWT} = this.state;

		return `${assuredURL}/auth?url=${pageURL}&site=${site.idsite}&token=${userJWT}`;

	}

	renderTableRow = ({profile, verb, siteName, tonsToDate, tickets}, key) => {
		return(
			<div class="row border-bottom mb-4" key={key}>
				<div class="col-5 col-md-4 col-lg-4 strong">
				    <p class="mb-0">{profile}</p>
				</div>
				<div class="col-2">
				    <p class="mb-0">{verb}</p>
				</div>
				<div class="col-5 col-md-6 col-lg-6 strong">
				    <p class="mb-0 text-right">{siteName}</p>
				</div>
				<div class="col-4 onenhalf-col mr-auto">
				    <p><span class="badge badge-pale badge-success strong">{tickets} Active Tickets</span></p>
				</div>
				<div class="col-4 onenhalf-col ml-auto">
				    <p><span class="badge badge-pale badge-dark float-right">{tonsToDate} Tons TD</span></p>
				</div>
            </div>
		)
	}

	render(){
		let {site, refreshSites} = this.props;
		//let {name, idsite, team_size} = site;
		return(<Fragment>
			
			
                <div class="row pl-0 pt-3 mb-2 border-bottom border-top">
                    <label class="ml-4">Agreements</label>
                </div>

				{
					site.projectsList.map((agreement, key)=> {
						return this.renderTableRow(agreement, key);
					})
				}

				<a class="btn btn-block btn-primary mb-4" href={this.link('active-tickets')}>View Tickets</a>

				<div class="col-12 pt-0 px-0 pb-3 clearfix">
                    <a class="link small strong text-primary cursor mr-auto" href={this.link("site-agreements")}>
                    	<u>{site.idprofile == 3 || site.idprofile == 4 ? "View" : "Manage" } Agreements</u>
                	</a>

					{site.team_size == 1 ?
						<button class="btn btn-outline-primary mt-4 float-right" data-toggle="modal" data-target={"#edit-team-"+site.idsite}>Start your Site Team</button>

					:
						<a class="link small strong text-primary cursor float-right" data-toggle="modal" data-target={"#edit-team-"+site.idsite}>
							<u>{site.idprofile == 3 || site.idprofile == 4 ? "View" : "Manage" } Site Team</u>
						</a>
	                }
                     
                </div>

				
			<ModalEditTeam idsite={site.idsite} name={site.name} refreshSites={refreshSites} />

		</Fragment>)
	}

}