import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Auth } from "aws-amplify";
import { url } from '../../constants'

export default class Logout extends Component {
  constructor(props) {
    super(props);
    this.handleSignOut();
  }

  handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        console.log("Signed-Out");
        //this.props.screenProps.onStateChange('signedOut', null);
        window.location.href = url.frontend+"/login";
      })
      .catch(err => console.log(err));
  }

  render = () => {
    return(<h1>Logging out...</h1>);
  }

}
