import React, {Fragment, Component} from 'react';

export default class Event extends Component {

  constructor(props){
    super(props);
  }
	
  componentWillMount = () => {
    this.props.markAsSeen();
  }

	render(){
    return (<Fragment>
      {alert("This is a test of the Event system. If all goes well, you won't see this alert ever again as this user.")}
    </Fragment>)
	}

}