import React, {Fragment, Component} from 'react';
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import TestPopup from './TestPopup';
import SetupGuideModal from './SetupGuideModal';
import PhoneNumberCompanyName from './PhoneNumberCompanyName'
import { apiURl, assuredAPIURL } from '../../constants';

export default class Event extends Component {

    constructor(props){

    	const userJWT = localStorage.getItem('token');
      const userDecoded = jwtDecode(userJWT);
      let username = userDecoded['cognito:username'];
      let email = userDecoded['email'];

    	super(props)

    	this.state={
        userDecoded,
    		username,
    		email,
        seen: true
    	}
	}


	componentWillMount = async () => {
		let {email} = this.state;
    let {eventtype} = this.props;

    ajax.post(`${assuredAPIURL}/users/auth`)
      .send(this.state.userDecoded)
      .end((error, response) => {
        if (!error && response) {
            ajax.get(`${apiURl}/events/getByEventTypeAndUsername/${eventtype}/${email}`)
              .end((error, response) => {
                if (!error && response) {
                    this.setState({seen: response.body.eventSeen})
                } else {
                    console.log('There was an error fetching', error);
                }
              }
            );                   
        } else {
          console.log('There was an error', error);
        }
      }
    );

	}

  markAsSeen = async () => {

    let {email} = this.state;
    let {eventtype} = this.props;

    ajax.post(`${apiURl}/events/markAsSeen`)
      .send({
        eventtype,
        email
      })
      .end((error, response) => {
        if (!error && response) {
            //this.setState({seen: true})
        } else {
            console.log('There was an error fetching', error);
        }
      }
    );

  }

	render(){
    let {eventtype} = this.props; 
    let {seen} = this.state;

    if (seen == true) return null;
    else {
  		/*if (eventtype == "testPopup"){
        return <TestPopup markAsSeen={this.markAsSeen} />
      }*/
      if (eventtype == "setupGuideModal"){
        return <SetupGuideModal markAsSeen={this.markAsSeen} />
      }
      else if (eventtype == "phoneNumberCompanyName"){
        return <PhoneNumberCompanyName markAsSeen={this.markAsSeen} />
      }
      else return null;
      return null;
    }
	}

}