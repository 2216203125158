import React, {Component} from 'react';
import ajax from 'superagent';
import _ from 'lodash';
import {assuredAPIURL} from '../../../constants';
import SuccessPopup from '../../common/Popup';
import jwtDecode from 'jwt-decode';

export default class ModalEditTeam extends Component{

    constructor(props){
        super(props);
        let idsite = 0;
        let siteName = "Default Site";
        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);

        this.state = {
            userId: null,
            idsite,
            canEditTeam: false,
            users: [],
            usersToAdd: [{idprofile: 2}],
            profilesList: [],
            usersObject: {},
            usersList: [],
            listOfProjects: [],
            disposalPartners: [],
            popupTriggerUsersAdded: false,
            popupTriggerUsersRemoved: false,
            popupTriggerUsersUpdated: false,
            usersToAddChanged: false,
            siteName,
            userDecoded
        }
        this.updateSite = this.updateSite.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
        this.renderAddUsers = this.renderAddUsers.bind(this);
        this.renderDisposalPartners = this.renderDisposalPartners.bind(this);
        this.renderDisposalPartnerRow = this.renderDisposalPartnerRow.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.verifyUsers = this.verifyUsers.bind(this);
    }

    componentWillMount(){

        ajax.post(`${assuredAPIURL}/users/auth`)
            .send(this.state.userDecoded)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({userId: response.body.user.iduser}, () => this.updateSite(this.props));
                } else {
                    console.log('There was an error', error);
                }
            }
        );

        ajax.get(`${assuredAPIURL}/profiles`)
            .end((error, response) => {
                if (!error && response) {
                    const validProfiles = response.body.profilesList.filter(item => {
                        return item.profile !== "Project Leader"
                    })
                    this.setState({profilesList: validProfiles})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );

    }

    updateSite(newProps){

        //console.log("Calling updateSite");
        
        //console.log("newProps is valid");

        this.setState({idsite: newProps.idsite, siteName: newProps.name})

        //console.log("newProps: ");
        //console.log(newProps);

        ajax.get(`${assuredAPIURL}/users/teammembersbysiteanduser/${newProps.idsite}/${this.state.userId}`)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({usersList: response.body.usersList});
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );

        ajax.get(`${assuredAPIURL}/users/disposalpartnerleadersbysite/${newProps.idsite}`)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({disposalPartners: response.body.usersList});
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );

       ajax.get(assuredAPIURL + '/sites/permissions/' + this.state.userId + '/' + newProps.idsite)
            .end((error, response) => {
                if (!error && response) {
                    //console.log("canEditTeam response:");
                    //console.log(response.body.permissions)
                    this.setState({canEditTeam: response.body.permissions.canEditTeam})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }


    componentWillReceiveProps(nextProps){
        if (nextProps)
            this.updateSite(nextProps);
    }

    renderUserGroup(group){
        return(
            group.map((item, index) => {
                let willBeRemoved = this.state.usersList[index].willBeRemoved;
                return(
                    <div className="col-12 col-lg-10 px-0 mx-lg-auto clearfix mb-4" key={index}>
                        <div className="lead">
                            {willBeRemoved ? 
                                <i className="ti-minus text-danger mt-2 mr-2 float-left"></i>
                                 : <i className="ti-check text-success mt-2 mr-2 float-left"></i>
                            }
                            {item.name ? <React.Fragment>
                                <strong className={willBeRemoved ? ' text-danger' : 'text-dark'}>{item.name}</strong> <span className={willBeRemoved ? ' text-danger' : 'text-dark'}> - {item.email}</span>
                            </React.Fragment>
                            : <strong className={willBeRemoved ? ' text-danger' : 'text-dark'}>{item.email}</strong>}
                            <a className="small item-remove text-dark float-right clearfix mt-1" href="#"
                            style = { this.state.canEditTeam ? {display: 'block'} : {display: 'none'}}
                            onClick={() => this.handleRemovalClick(willBeRemoved, item, index)}>
                                <i className={"ti-close" + (willBeRemoved ? ' text-danger' : '') }></i>
                            </a>
                        </div>
                        <div className={"small ml-5 float-left"+ (willBeRemoved ? ' text-danger' : '') }>{item.profile}</div>
                    </div>
                )
            })
        )
    }

    renderUsers(){
        return (<React.Fragment>
            {this.state.usersList.length > 0 ? this.renderUserGroup(this.state.usersList) : <span>No Team Members</span>}
        </React.Fragment>)
    }

    handleRemovalClick = (userMarkedForRemovalAlready, user, index) => {
        if (userMarkedForRemovalAlready){
            this.unRemoveUser(user, index);
        } else {
            this.removeUser(user, index);
        }
    }

    unRemoveUser = (user, index) =>{
        let usersList = this.state.usersList;
        usersList[index].willBeRemoved = false;
        this.setState({usersList});

    }

    removeUser = (user, index) =>{
        let usersList = this.state.usersList;
        usersList[index].willBeRemoved = true;
        this.setState({usersList});

    }

    renderOptions() {
        return this.state.profilesList.map(r => {
            return <option value={r.idprofile} key={r.idprofile}>{r.profile}</option>
        })
    }

    updateProfile(key, value) {
        this.setState({usersToAddChanged: true}, 
            this.setState(state => {
                const usersToAdd = this.state.usersToAdd.map((item, j) => {
                    if(j == key){
                        return Object.assign({}, item, {idprofile: value});
                    }else{
                        return item;
                    }
                });
                return {
                    usersToAdd
                }
            })
        );
       
    }
    
    updateEmail(key, value) {
        this.setState({usersToAddChanged: true}, 
            this.setState(state => {
                const usersToAdd = this.state.usersToAdd.map((item, j) => {
                    if(j == key){
                        return Object.assign({}, item, {email: value});
                    }else{
                        return item;
                    }
                });
                return {
                    usersToAdd
                }
            })
        );
    }

    renderDisposalPartnerRow(item, key) {

        return(
            <div className="col-12 col-lg-10 px-0 mx-lg-auto clearfix mb-4" key={key}>
                <div className="lead">
                    <i className="ti-check text-success mt-2 mr-2 float-left"></i>
                    <strong className="text-dark">{item.email}</strong>
                </div>
                <div className="small ml-5 float-left">{item.site}</div>
            </div>)
    }

    renderDisposalPartners(){
        if (this.state.disposalPartners.length > 0){
            return (
                <React.Fragment>
                    {
                        this.state.disposalPartners.map((item, index) => {
                            return this.renderDisposalPartnerRow(item, index);
                        })
                    }
                </React.Fragment>
            )
        } else {
            return(
                <ul className="list-unstyled lead col-lg-11 mt-2 px-0 ml-lg-4">
                    <span>No Disposal Partners</span>
                </ul>
            )
        }
    }

    renderAddUserRow(key){

        return(
            <React.Fragment key={key}>
                <div className="col-lg-7 p-0 pl-lg-4">
                    <div className="contrast">
                        <input className={"form-control" + (this.state.usersToAdd[key].invalidEmail ? " is-invalid" : "")} 
                        type="email"  placeholder="name@company.com"
                        value={this.state.usersToAdd[key].email || ""} 
                        onChange={(e) => {
                            this.updateEmail(key, e.target.value)
                        }} />
                    </div>
                </div>
                <div className="col-12 col-lg-5 pt-2 px-0 pt-lg-0 pl-lg-3 contrast">
                    <select className={"form-control" + (this.state.usersToAdd[key].invalidProfile ? " is-invalid" : "")}
                        value={this.state.usersToAdd[key].idprofile || 0} 
                        onChange={e => { this.updateProfile(key, ~~e.target.value); }}>
                        {this.renderOptions()}
                    </select>
                </div>

            </React.Fragment>
        )
    }

    renderOptions() {
        return this.state.profilesList.map(r => {
            return <option value={r.idprofile} key={r.idprofile}>{r.profile}</option>
        })
    }

    renderAddUsers() {
        return this.state.usersToAdd.map((item, index) => {
            return this.renderAddUserRow(index)
        })
    }

    addNewUserRow(){
        this.setState(state => {
            const usersToAdd = [...state.usersToAdd, {idprofile: 2}];
            return {usersToAdd}
        })
    }

    verifyUsers(){

        //if no users are being added, just say all 0 of them are valid by default
        if (this.state.usersToAddChanged === false) return true;

        //Otherwise, check
        let validUsersList = [];
        let hasInvalidUsers = false;

        for (var i = 0; i < this.state.usersToAdd.length; i++){

            let userIsValid = this.verifyIndividualUser(i);
            if (userIsValid === true){
                validUsersList.push(this.state.usersToAdd[i]);
            } else if (userIsValid === false) {
                hasInvalidUsers = true;
            }
        }

        if (hasInvalidUsers){
            this.setState({hasInvalidUsers: true});
            return false;
        } else { 
            this.setState({hasInvalidUsers: false});
            return validUsersList;
        }
    }

    verifyIndividualUser(i){
        let currentuser = this.state.usersToAdd[i];
        let validUser = true;

        if (currentuser.email && !currentuser.idprofile){
            validUser = false;
            this.state.usersToAdd[i].invalidEmail = false;
            this.state.usersToAdd[i].invalidProfile = true;
        }

        else if (!currentuser.email && currentuser.idprofile){
            validUser = null;
            this.state.usersToAdd[i].invalidProfile = false;
            this.state.usersToAdd[i].invalidEmail = false;
        }

        else if ((currentuser.email === undefined) && currentuser.idprofile === 1){
            validUser = null;
            this.state.usersToAdd[i].invalidProfile = false;
            this.state.usersToAdd[i].invalidEmail = false;
        }

        else if (currentuser.email && currentuser.idprofile){
            validUser = true;
            this.state.usersToAdd[i].invalidProfile = false;
            this.state.usersToAdd[i].invalidEmail = false;
        }

        return validUser;
    }

    
    sendData(){
        let verifiedUsers = this.verifyUsers();
        if (verifiedUsers === false) return;

        const addUsersData = {
            idsite:         this.state.idsite,
            userdata:       verifiedUsers,
            idleader:       this.state.userId,
            webUrl:         null
        };

        const removeUsersData = {idsite: this.state.idsite, usersToRemove: []};
        let usersList = this.state.usersList;
        for (var i = 0; i < usersList.length; i++){
            if (usersList[i].willBeRemoved) removeUsersData.usersToRemove.push(usersList[i].iduser);
        }

        if (removeUsersData.usersToRemove.length > 0){
            ajax.post(`${assuredAPIURL}/sites/deleteSiteUsers`)
            .send(removeUsersData)
            .then(res => {
                if (verifiedUsers.length > 0){
                     ajax.post(`${assuredAPIURL}/sites/addSiteUsers`)
                    .send(addUsersData)
                    .then(res => {
                        this.setState({popupTriggerUsersUpdated: true});
                        if (this.props.refreshSites) this.props.refreshSites();
                    });
                } else {
                    this.setState({popupTriggerUsersRemoved: true});
                    if (this.props.refreshSites) this.props.refreshSites();
                }
            });   
        }

        else if (verifiedUsers.length > 0){
            ajax.post(`${assuredAPIURL}/sites/addSiteUsers`)
            .send(addUsersData)
            .then(res => {
                this.setState({popupTriggerUsersAdded: true});
                if (this.props.refreshSites) this.props.refreshSites();
            });
        } else {
            console.log("No changes made");
        }
    }

    refreshModal = () => {
        document.getElementById("close-the-edit-team-modal").click();
        this.setState({
            usersToAddChanged: false,
            usersToAdd: [{idprofile: 1}],
            popupTriggerUsersUpdated: false,
            popupTriggerUsersAdded: false,
            popupTriggerUsersRemoved: false
        });
        this.updateSite({name: this.state.siteName, idsite: this.state.idsite});
    }


    render(){

        return(<React.Fragment>
            <div className="modal" id={"edit-team-"+this.state.idsite} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="alert alert-primary" role="alert">
                            <strong>{this.state.canEditTeam ? "Edit" : "View"} Site Team</strong>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-the-edit-team-modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <div className="row m-0">
                                <h5 className="ml-lg-3">{this.state.siteName}</h5>
                                <div className="lead col-lg-11 mt-5 px-0 ml-lg-4">
                                    {this.renderUsers()}
                                </div>
                                <div className="col-12 col-lg-11 mt-6 mx-lg-auto" style = { this.state.canEditTeam ? {display: 'block'} : {display: 'none'}}>
                                    <div className="form-group row gap-y">
                                        <div className="col-lg-12 mx-lg-auto px-0">
                                            <label className="lead fw-400">Add to Site Team</label>
                                            <button className="btn btn-xs btn-outline-dark float-right" style={{display: 'none'}}>Import Contacts</button>
                                        </div> 
                                        {this.renderAddUsers()}
                                        <button type="button" onClick={this.addNewUserRow.bind(this)} className="btn btn-outline-secondary mb-5 mb-lg-0 ml-lg-4 px-4"><i className="fa fa-plus fw-400"></i></button>
                                    </div>
                                </div>
                                <hr className="col-11 mb-6" />
                                <h5 className="ml-lg-3">Disposal Partners</h5>
                                <div className="lead col-lg-11 mt-2 px-0 ml-lg-4">
                                    {this.renderDisposalPartners()}
                                </div>
                            </div>
                        </div>
                        <button type="button" className="btn btn-lg btn-primary"
                        style = { this.state.canEditTeam ? {display: 'block'} : {display: 'none'}} onClick={this.sendData.bind(this)} >
                            Update Team
                        </button>
                    </div>
                </div>
            </div>

            <SuccessPopup
                popupId="popupUsersAdded"
                title="Success!"
                message="Users added!"
                trigger={this.state.popupTriggerUsersAdded}
                callback={this.refreshModal}
                 />
            <SuccessPopup
                popupId="popupUsersRemoved"
                title="Success!"
                message="Users removed!"
                trigger={this.state.popupTriggerUsersRemoved}
                callback={this.refreshModal}
                 />
            <SuccessPopup
                popupId="popupUsersUpdated"
                title="Success!"
                message="Users updated!"
                trigger={this.state.popupTriggerUsersUpdated}
                callback={this.refreshModal}
                 />

      </React.Fragment>)

    }

}
