/****
*
*  This is a "core" file for the EI Platform.
*  Please keep this file identical accross all platforms on which it appears.
*  If you must implement app-specific functionality, implement that in a wrapper component.
*
*/

import React, {Fragment, Component} from 'react';
import ajax from 'superagent';

class NotificationItem extends Component{

    constructor(props){
        super(props);

        this.state = {
            read: false,
            dateCreated: this.formatDate(props.notification.datecreated),
            heading: this.createHeading(props.notification),
            body: this.createBody(props.notification),
            idnotification: this.props.notification.idnotification,
            idnotificationtype: this.props.notification.idnotificationtype,
            cleared: false,
        }       
    }

    componentWillReceiveProps(newProps){
        if (newProps.read){
            this.setState({read: true});
        } else if (!newProps.read) {
            this.setState({read: false});
        }
    }

    formatDate = (datetime) => {
        let monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

        let dateObj = new Date(datetime);
        let month = dateObj.getMonth();
        let date = dateObj.getDate();
        let year = dateObj.getFullYear();
        let hour = dateObj.getHours();
        let minutes = dateObj.getMinutes();
        if (minutes < 10) minutes = "0" + minutes;
        let suffix = "AM";

        if (hour >= 12){
            hour -= 12; 
            suffix = "PM";
            if (hour == 0) hour = 12;
        } 

        return monthNames[month] + " " + date + " " + year + ", " + hour + ":" + minutes + suffix;

    }

    toggleRead = () => {
        console.log("Toggling read for notification " + this.state.idnotification + ": " + this.state.read);
        if (this.state.read){
            this.setState({read: false});
            this.markAsUnread();
        } else {
            this.setState({read: true});
            this.markAsRead();
        }
    }

    createBody = (notification) => {
        let type = notification.idnotificationtype;

        if (type == 1){
            return notification.partnerSiteName + " Has Sent You An Invite to Collaborate";
        } else if (type == 2){
            return "We're Happy To Have You!";
        } else if (type == 3){
            return "Successfully Created " + notification.siteName;
        } else if (type == 4){
            return "New Collab Agreement Created Between "+notification.exportSite+" And "+notification.importSite;
        } else if (type == 5){
            return "New Admin Agreement Created Between "+notification.exportSite+" And "+notification.importSite;
        } else if (type == 6){
            return (<React.Fragment>You have been added to {notification.siteName} with {notification.profile} permissions</React.Fragment>)
        }  else if (type == 7){
            let linkdata = JSON.parse(notification.linkdata);
            return (<React.Fragment>You have been added to {linkdata.pmGroupName}</React.Fragment>)
        }   else if (type == 8){
            let linkdata = JSON.parse(notification.linkdata);
            return (<React.Fragment>Successfully Created {linkdata.pmGroupName}</React.Fragment>)
        } else if (type == 9){
            let linkdata = JSON.parse(notification.linkdata);
            return (<React.Fragment>You shared your {linkdata.siteName} location with {linkdata.partnerName}.</React.Fragment>)
        } else if (type == 10){
            let linkdata = JSON.parse(notification.linkdata);
            return (<React.Fragment>{linkdata.partnerName} has shared their {linkdata.siteName} location with you.</React.Fragment>)
        }
    }

    createHeading = notification => {
        let type = notification.idnotificationtype;

        if (type == 1){
            return "Complete Agreement Setup";
        } else if (type == 2){
            return "Welcome to Assured Disposal!";
        } else if (type == 3){
            return "New Site";
        } else if (type == 4){
            return "New Collab Agreement"
        } else if (type == 5){
            return "New Admin Agreement"
        } else if (type == 6){
            return "Invited To Site"
        } else if (type == 7){
            return "Added to PM Group"
        } else if (type == 8){
            return "Created PM Group"
        } else if (type == 9){
            return "Shared Your Site"
        } else if (type == 10){
            return "Site Shared with You"
        }
    }


    markAsCleared = () => {
       this.setState({cleared: true});
       this.markNotification("Cleared");
    }

    markAsRead = () => {
        this.setState({read: true});
        this.markNotification("Read");
    }

    markAsUnread = () => {
        this.setState({read: false});
        this.markNotification("Unread")
    }

    markNotification = (mode) => {

        let {assuredAPIURL} = this.props;

        let newState = {};
        if (mode == "Read"){
            newState.read = true;
        } else if (mode == "Unread") {
            newState.read = false;
        } else if (mode == "Cleared") {
            newState.cleared = true;
        }

        ajax.post(assuredAPIURL+"/notifications/markAs"+mode)
        .send({idnotification: this.state.idnotification})
        .then(res => {
            this.setState(newState);
        }).catch(res =>{
            alert('Error marking notification as '+mode+'!');
        });
    }

    onClickHandler = () => {
        let {assuredPageUrls, assuredFrontendURL} = this.props;
        if (this.state.idnotificationtype == 1){
            window.location = assuredFrontendURL + assuredPageUrls.completeAgreement + "/" + this.props.notification.linkdata;
        }
    }



render(){

    let type = this.state.idnotificationtype;

    if (this.state.cleared) return null;
      return(
        <Fragment>
            <div className="row m-0 p-0">
                <div className="col-10 m-0 p-0">
                    <a className="small nav-link text-dark pb-0" style={(type == 1 ? {cursor: 'pointer'} : null)} onClick={this.onClickHandler}>
                        {!this.state.read ?
                            <strong>{this.state.heading}</strong>
                        : this.state.heading
                        }
                    </a>
                </div>
                <div className="col-1 px-2 py-0 ml-4">
                    <a href="#" class={this.state.read ? "text-light" : this.props.type=="notification" ? "text-primary" : "text-dark"}  onClick={this.toggleRead}>
                        <i class={"small-6 fa fa-circle"+(this.state.read ? "-o" : "")+" mr-0"}></i>
                    </a>
                </div>
                <div className="col-12 px-4 py-1 ml-2">
                    <a className={"small-4 text-" + (this.state.read ? "light" : "dark")} onClick={this.onClickHandler}>
                        <p className="mb-2">{this.state.body}
                    </p></a>
                </div>
                <div className="col-12 lh-1 mb-3 flexbox">
                    <a href="#" className="small-6 text-light">{/*<i class={"fa fa-"+(this.state.read ? "truck" : "bullseye")+" ml-1 mr-2"} aria-hidden="true"></i>*/}{this.state.dateCreated}</a>
                    <a href="#" className="small-6 text-light" onClick={this.markAsCleared}
                    style={this.props.type == "task" ? {display: "none"} : {display: "inline-block"}}>
                    Clear <i className="small-8 text-dark ti ti-close ml-1"></i>
                </a>
                </div>
            </div>

            <div className="nav-divider"></div>
         
        </Fragment>)
  }

}

export default NotificationItem;