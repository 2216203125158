import React, {useEffect, useState}  from 'react';
import { useSelector } from "react-redux";
import _ from 'lodash';
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import MapContainer from './googlemap'
import Sidebar from '../Sidebar'
import SiteInfo from './SiteInfo';
import { createSite } from '../../actions/Sites';
import {haulcheckURl, assuredURL} from '../../constants';
import ModalEditTeam from './ModalEditTeam'
import Event from '../Event'
import '../../assets/css/page.css'
import './homepage.css'

export default function Home() {

  const [mapLocked, setMapLocked] = useState(true);
  const [siteRefresh, setSiteRefresh] = useState(false);

  return (

    <div>

      <Navbar />

      <main className="main-content">
        <section className="section py-0 shadow-2">
          <div className="row no-gutters">

            <div class="col-12 col-md-6 col-lg-5 p-0 p-md-3 px-lg-7 pt-lg-4" >
              <Sidebar mapLocked={mapLocked} setMapLocked={setMapLocked} siteRefresh={siteRefresh} setSiteRefresh={setSiteRefresh}/>
            </div>

            <div className="col-md-6 col-lg-7" style={{minHeight: '700px', maxHeight: '700px'}}>
              <MapContainer locked={mapLocked} setSiteRefresh={setSiteRefresh} />
            </div>

          </div>
        </section>
      </main>

      <Event eventtype={"setupGuideModal"} />
      <Event eventtype="phoneNumberCompanyName" />

      <Footer />
    </div>
  )
}
