import { TRANSACTIONS } from '../constants/types';

const initialState = {
  getTransactionsGroupsByUser: {
    inProgress: false,
    success: false,
    error: false,
    data: []
  },
  getTransactionsByUser: {
    inProgress: false,
    success: false,
    error: false,
    data: []
  },
  getTransactionById: {
    inProgress: false,
    success: false,
    error: false,
    data: []
  },
  getTransactionTermsById: {
    inProgress: false,
    success: false,
    error: false,
    data: []
  },
  updateTermsBuilder: {
    inProgress: false,
    success: false,
    error: false,
    data: []
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTIONS.GET_TRANSACTIONS_GROUPS_BY_USER_REQUESTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionsGroupsByUser: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTIONS_GROUPS_BY_USER_REJECTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionsGroupsByUser: {
          inProgress,
          success,
          error,
          data
        }
      })
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTIONS_GROUPS_BY_USER_FULFILLED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionsGroupsByUser: {
          inProgress,
          success,
          error,
          data
        }
      })
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTIONS_BY_USER_REQUESTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionsByUser: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTIONS_BY_USER_REJECTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionsByUser: {
          inProgress,
          success,
          error,
          data
        }
      })
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTIONS_BY_USER_FULFILLED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionsByUser: {
          inProgress,
          success,
          error,
          data
        }
      })
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTION_BY_ID_REQUESTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionById: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTION_BY_ID_REJECTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionById: {
          inProgress,
          success,
          error,
          data
        }
      })
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTION_BY_ID_FULFILLED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionById: {
          inProgress,
          success,
          error,
          data
        }
      })
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTION_TERMS_BY_ID_REQUESTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionTermsById: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTION_TERMS_BY_ID_REJECTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionTermsById: {
          inProgress,
          success,
          error,
          data
        }
      })
      return newState;
    }
    case TRANSACTIONS.GET_TRANSACTION_TERMS_BY_ID_FULFILLED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getTransactionTermsById: {
          inProgress,
          success,
          error,
          data
        }
      })
      return newState;
    }
    case TRANSACTIONS.UPDATE_TERMS_BUILDER_REQUESTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        updateTermsBuilder: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case TRANSACTIONS.UPDATE_TERMS_BUILDER_REJECTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        updateTermsBuilder: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case TRANSACTIONS.UPDATE_TERMS_BUILDER_FULFILLED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        updateTermsBuilder: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    default:
      return state;
  }
}
