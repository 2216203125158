/****
*
*  This is a "core" file for the EI Platform.
*  Please keep this file identical accross all platforms on which it appears.
*  If you must implement app-specific functionality, implement that in a wrapper component.
*
*/

import React from 'react'
import ajax from 'superagent';
import LetterAvatar from './LetterAvatar';
import NotificationItem from './NotificationItem';

export default class NavbarCore extends React.Component {
    constructor(props){
        super(props);

        let showSites = props.showSites ? props.showSites : false;
        let showSandbox = props.showSandbox  ? props.showSandbox : false;
        let showAgreements = props.showAgreements ? props.showAgreements : false;

        let showSubMenu = showSites || showSandbox || showAgreements;

        this.state = {
            notifications: [],
            numNotifications: 0,
            tasks: [],
            numTasks: 0,
            iduser: null,
            navbarOpen: false,
            showSites,
            showSandbox,
            showAgreements,
            showSubMenu
        }
    }

    componentWillReceiveProps = (newProps) => {

        if (newProps.iduser){
            this.fetchNotifications(newProps.iduser)
        }
    }  

    /*componentWillUnmount = () => {
        clearInterval(this.intervalId);
    }*/

    fetchNotifications = (iduser) => {
        let {assuredAPIURL} = this.props;

        if (iduser == null) return;

        ajax.get(`${assuredAPIURL}/notifications/uncleared/${iduser}`)
            .end((error, response) => {
                if (!error && response) {
                    this.filterNotifications(response.body.notifications);
                } else {
                    //console.log('There was an error', error);
                }
            }
        );
    }

    filterNotifications = (all_notifications) => {

        let taskTypes = [1];
        let notificationTypes = [2,3,4,5,6,7,8,9,10];


        let tasks = all_notifications.filter(item => {
            return taskTypes.indexOf(item.idnotificationtype) != -1;
        });

        let numTasks = tasks.reduce((total, item) => {
            if (item.datereceived == null)
                return total + 1
            else return total;
        }, 0);

        let tasksRead = tasks.reduce((total, item) => {
            if (item.dateread == null)
                return total + 1
            else return total;
        }, 0);

        let notifications = all_notifications.filter(item => {
            return notificationTypes.indexOf(item.idnotificationtype) != -1;
        });

        let numNotifications = notifications.reduce((total, item) => {
            if (item.datereceived == null)
                return total + 1
            else return total;
        }, 0);

        let notificationsRead = notifications.reduce((total, item) => {
            if (item.dateread == null)
                return total + 1
            else return total;
        }, 0);

        this.setState({tasks, notifications, numTasks, numNotifications, tasksRead, notificationsRead});
    }

    renderSwitchAppsMenu = () => {
        let {assuredFrontendURL, eiFrontendUrl, haulcheckFrontendURL} = this.props;
        /*return(
        <ul className="nav nav-navbar mr-auto">
            <li className="nav-item">
                <a className="nav-link fs-11 p-0 ml-4 mt-2" href="#">
                    Switch Apps <i className="fa fa-angle-down" aria-hidden="true"></i>
                </a>
                <nav className="nav widebox">
                    <a className="nav-link" href={eiFrontendUrl}><i className="fa fa-map-o mr-1" aria-hidden="true"></i> My Sandbox Map</a>
                    <div className="nav-divider"></div>
                    <a className="nav-link" href={assuredFrontendURL + "/auth?url=assured-disposal-sites&token="+localStorage.getItem('token')}><i className="fa fa-truck mr-1" aria-hidden="true"></i> Assured Disposal Sites</a>
                    {<a className="nav-link" href={haulcheckFrontendURL + "/auth?url=haul-check-sites&token="+localStorage.getItem('token')}><i className="fa fa-bullseye mr-1" aria-hidden="true"></i> Haul Check Sites</a>}
                </nav>
            </li>
        </ul>
        );*/
        return null;
    }

    renderNotificationsMenu = () => {
        return(
            <ul className="nav nav-navbar ml-auto">
                <li className="nav-item" onClick={() => this.markAll("notifications", "Received")}>
                    <a className="nav-link dropdown-toggle no-caret" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">
                        <i className="fa fa-bell fs-16" aria-hidden="true"></i>
                        {this.state.numNotifications > 0 ?
                            <span className="badge badge-number badge-warning fs-10 pt-1 pl-1 mb-1">
                                {this.state.numNotifications}
                            </span>
                            : null }
                    </a>
                    <div className="dropdown-menu widestbox align-right" style={{minWidth: '18em'}} aria-labelledby="navbarDropdown">
                        <div class="row m-0 pr-4 flexbox">
                            <h6 class="dropdown-header ml-4">Notifications</h6>
                            {this.state.notifications.length > 0 ?
                                (this.state.notificationsRead > 0 ?
                                    <a class="small-6 text-light" href="#" onClick={() => this.markAll("notifications", "Read")}>Mark all as read</a>
                                : <a class="small-6 text-light" href="#" onClick={() => this.markAll("notifications", "Cleared")}>Clear all</a> )
                            : null }
                        </div>

                        <div class="nav-divider"></div>

                        {this.renderNotifications()}

                        {this.state.notifications.length > 0 ? <div className="nav-divider"></div> : null}

                        {false ? <div className="nav-divider"></div> : null }
                        {/* <a className="nav-link active" href="activity-log.html"><i className="fa fa-history mr-1" aria-hidden="true"></i> View Activity Log</a>*/}
                    </div>
                </li>
            </ul>
        );

    }

    renderTasksMenu = () => {
        return(
            <ul className="nav nav-navbar">
                <li className="nav-item dropdown" onClick={() => this.markAll("tasks", "Received")}>
                    <a className="nav-link dropdown-toggle no-caret pt-1" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">
                        <i className="fa fa-tasks fs-18" aria-hidden="true"></i>

                        { (this.state.numTasks > 0) ?
                            <span className="badge badge-number badge-danger fs-10 pt-1 pl-1">{this.state.numTasks}</span>
                            : null }
                    </a>
                    <div className="dropdown-menu widestbox align-right" style={{minWidth: '18em'}} aria-labelledby="navbarDropdown">
                        <div class="row m-0 pr-4 flexbox">
                            <h6 class="dropdown-header ml-4">Tasks</h6>
                            {this.state.tasks.length > 0 ?
                                 <a className="small-6 text-light" href="#" onClick={() => this.markAll("tasks", "Read")}>Mark all as read</a>
                            : null }
                        </div>

                        <div className="nav-divider"></div>

                        {this.renderTasks()}

                        {this.state.tasks.length > 0 ? <div className="nav-divider"></div> : null}

                        {true ? null : <div className="nav-divider"></div> }

                        {true ? null : <a className="nav-link active" href="activity-log.html"><i className="fa fa-history mr-1" aria-hidden="true"></i> View Activity Log</a> }
                    </div>
                </li>
            </ul>
        );
    }

    renderTasks = () => {
        let {assuredAPIURL, assuredPageUrls, assuredFrontendURL} = this.props;

        if (this.state.tasks.length === 0){
            return(
                <a className="nav-link">No tasks!</a>
            )
        }

        return this.state.tasks.map((task, index) => {
            return (<React.Fragment key={index} >
                <NotificationItem 
                    notification={task} 
                    type="task" 
                    read={task.dateread != null} 
                    assuredAPIURL={assuredAPIURL}
                    assuredPageUrls={assuredPageUrls}
                    assuredFrontendURL={assuredFrontendURL}
                />
            </React.Fragment>)
        });

    }

    renderNotifications = () => {
        let {assuredAPIURL, assuredPageUrls} = this.props;

        if (this.state.notifications.length === 0){
            return(
                <a className="nav-link">No notifications!</a>
            )
        }

        return this.state.notifications.map((notification, index) => {
            return (<React.Fragment key={index} >
                <NotificationItem 
                    notification={notification} 
                    type="notification" 
                    read={notification.dateread != null} 
                    assuredAPIURL={assuredAPIURL}
                    assuredPageUrls={assuredPageUrls}
                />
            </React.Fragment>)
        })
    }



    markAll = (type, markAs) => {
        let {assuredAPIURL} = this.props;

        if (markAs == "Cleared"){
            this.setState({[type]: []})
        } else if (markAs == "Received") {
            let varName = "num"+this.toTitleCase(type);
            this.setState({[varName]: 0});
        } else if (markAs == "Read") {
            if (type == "tasks" && this.state.tasksRead == 0) return;
            let items = this.state[type];

            items.map((item, i) => {
                item.dateread = true;
            })

            this.setState({[type]: items, [type+"Read"]: 0});
        }

        ajax.post(assuredAPIURL+"/notifications/markAs"+markAs)
        .send({notifications: this.state[type]})
        .then(res => {
            console.log("Success")
            this.fetchNotifications();
        }).catch(res =>{
            //alert('Error!');
            console.log("Error!");
            console.log(res);
        });

    }

    toTitleCase = (string) => {
        return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
    }


    renderSupportMenu = () => {
        return(<React.Fragment>
            <ul className="nav nav-navbar">
                <li className="nav-item">
                    <a className="nav-link" href="#">
                        <i className="fa fa-question-circle fs-20" aria-hidden="true"></i>
                        {/*<span className="badge badge-dot badge-primary mb-5 p-1"></span>*/}
                    </a>
                    {/*<nav class="nav widestbox align-right" style={{minWidth: "18rem"}}>
                        <h6 class="dropdown-header ml-4">Support</h6>
                        <div class="nav-divider"></div>
                        <h6 class="dropdown-header ml-4">Assured Disposal</h6>
                        <a class="nav-link" href="#" data-toggle="offcanvas" data-target="#offcanvas-whatisad-1">What is Assured Disposal?</a>
                        <a class="nav-link" href="#" data-toggle="offcanvas" data-target="#offcanvas-setup-1">Getting Set Up</a>
                        <a class="nav-link" href="#" data-toggle="offcanvas" data-target="#offcanvas-tickets-1">Working with Tickets</a>
                        <a class="nav-link" href="#" data-toggle="offcanvas" data-target="#offcanvas-trucks-1">Tracking Trucks</a>
                        <a class="nav-link" href="#" data-toggle="offcanvas" data-target="#offcanvas-tonnage-1">Tonnage Logs</a>
                        <div class="nav-divider"></div>
                        <a class="nav-link" href="tel:262-682-1146">Call us! <b>(262) 682-1146</b></a>
                        <a class="nav-link" href="../downloads/support-guide.pdf"><i class="ti ti-agenda pr-1" aria-hidden="true"></i>Download the Guide</a>
                    </nav>*/}
                    <nav class="nav widestbox align-right" style={{minWidth: "18rem"}}>
                        <h6 class="dropdown-header ml-4">Support</h6>
                        <div class="nav-divider"></div>

                        <li class="nav-item">
                            <a class="nav-link" href="#">I am a Site Leader</a>
                            <ul class="nav">
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#getting-set-up-guide-modal">Getting Set Up</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guide-modal">Site-based Field Use</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#tracking-and-reports-guide-modal">Tracking & Reports</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#">I've been invited to an Agreement</a>
                            <ul class="nav">
                                <li class="nav-item">
                                    <a class="nav-link" href="#" data-toggle="modal" data-target="#completing-setup-getting-started-guide-modal">Completing Setup / Getting Started</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guide-modal">Site-based Field Use</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#tracking-and-reports-guide-modal">Tracking & Reports</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#">I've been invited as a Co-Leader</a>
                            <ul class="nav">
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#how-it-works-guide-modal">How It Works</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guide-modal">Site-based Field Use</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#tracking-and-reports-guide-modal">Tracking & Reports</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#">I've been invited as a Team Member</a>
                            <ul class="nav">
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#how-it-works-guide-modal">How It Works</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-team-member-guide-modal">Site-based Field Use</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#tracking-hauls-guide-modal">Tracking Hauls</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#">I've been invited as a Guest</a>
                            <ul class="nav">
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#how-it-works-guide-modal">How It Works</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guest-guide-modal">Site-based Ticket Tracking</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="modal" data-target="#trucks-tracking-and-reports-guide-modal">Trucks, Tracking, & Reports</a>
                                </li>
                            </ul>
                        </li>


                        <div class="nav-divider"></div>
                        <a class="nav-link" href="tel:262-682-1146">Call us! <b>(262) 682-1146</b></a>
                        <a class="nav-link" href="../downloads/support-guide.pdf"><i class="ti ti-agenda pr-1" aria-hidden="true"></i>Download the Guide</a>
                    </nav>
                </li>
            </ul>
        </React.Fragment>);
    }

    renderUserMenu = () => {
        let {eiFrontendUrl, assuredPageUrls, logout, username} = this.props;

        return(
            <ul className="nav nav-navbar">
                <li className="nav-item">
                    <a className="nav-link" href="#">
                        <img className="round mb-1" width="24" height="24" style={{borderRadius: '50px'}} src={LetterAvatar(username)} />
                    </a>
                    <nav className="nav align-right">
                        <h6 className="ml-4">Hi {username}!</h6>
                        <div className="nav-divider"></div>
                        <a className="nav-link" href={eiFrontendUrl + assuredPageUrls.myAccountPage}>My Account</a>
                        {true ? null : <a className="nav-link" href="referrals.html">Refer a Colleague</a>}
                        <div className="nav-divider"></div>
                        <a className="nav-link" href="#" onClick={() => logout()}>Log Out</a>
                    </nav>
                </li>
            </ul>
        );
    }

    renderSiteAgreementsInSubBar = () => {
        let { assuredPageUrls} = this.props;
        return (<React.Fragment>
            <a className="nav-link h6 ml-0 py-0" href={assuredPageUrls.agreementsList}>
                Site Agreements
            </a>
        </React.Fragment>)
    }

    renderMySitesInSubBar = () => {
        let {assuredPageUrls} = this.props;
        return (<React.Fragment>
            <a className="nav-link h6 ml-0 py-0" href={assuredPageUrls.mySites}>
                {this.state.showSandbox ? null : <i class="fa fa-angle-left mx-2" aria-hidden="true"></i>}
                Assured Disposal Sites
            </a>
            {this.state.showAgreements ? "/" : null}
        </React.Fragment>)
    }

    renderSandboxInSubBar = () => {
        let {eiFrontendUrl} = this.props;
        return (<React.Fragment>
            <a className="nav-link h6 ml-0 py-0" href={eiFrontendUrl}>
                <i class="fa fa-angle-left mx-2" aria-hidden="true"></i>My Sites
            </a>
            {this.state.showSites ? "/" : null}
        </React.Fragment>)
    }

    render(){

        let {logo} = this.props;

        return(<React.Fragment>
            <nav className="navbar navbar-expand-lg navbar-dark bg-gray" data-navbar="static">
                <div className="container col-12 p-0 m-0">
                    <div className="navbar-left">

                        <a className="navbar-brand mr-auto" href="#">
                            <img className="logo-dark mb-2 mt-2 mr-3" src={logo} alt="logo" width="200em" />
                            <img className="logo-light mb-2 mt-2 mr-3" src={logo} alt="logo" width="200em" />
                        </a>

                        <p class="badge badge-pale badge-primary p-1 mt-1 fs-10 strong">BETA</p>
                    </div>

                    <button className="navbar-toggler mr-0" type="button" onClick={() => {this.setState({navbarOpen: true})}}>

                        {(this.state.numNotifications > 0) ?
                            <span className="badge badge-dot badge-warning mb-5 p-1"></span>
                        : null }

                         { (this.state.numTasks > 0) ?
                           <span className="badge badge-dot badge-danger mb-5 p-1"></span>
                        : null }

                        <i className="fa fa-bars" aria-hidden="true"></i>
                    </button>

                    <section className="navbar-mobile">

                        {this.renderSwitchAppsMenu()}
                        {this.renderNotificationsMenu()}
                        {this.renderTasksMenu()}
                        {this.renderSupportMenu()}
                        {this.renderUserMenu()}

                    </section>

                    <div className="navbar-toggler" style={ this.state.navbarOpen ? 
                        {/*'backgroundColor': '#ff00ff',*/ height: '100vh', width: '100vw', position: 'absolute', top: '0', left: '0', zIndex: '1000'}
                         : {'display': 'none'} } onClick={() => {this.setState({navbarOpen: false})}}>
                    </div>
                </div>
            </nav>

            {this.state.showSubMenu ? <nav className="nav navbar-dark border-bottom">
                {this.state.showSandbox ? this.renderSandboxInSubBar() : null}
                {this.state.showSites ? this.renderMySitesInSubBar() : null}
                {this.state.showAgreements ? this.renderSiteAgreementsInSubBar() : null}
            </nav> : null }
        </React.Fragment>
    );
  }
}
