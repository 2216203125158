
import { UTILS } from '../constants/types';

export function isPanelOpen(status) {
  return {
    type: UTILS.IS_PANEL_OPEN,
    status,
  }
}
export function setAssuredSiteId(assuredSiteId) {
  return {
    type: UTILS.SET_ASSURED_SITE_ID,
    assuredSiteId,
  }
}
export function setAssuredProfileId(assuredProfileId) {
  return {
    type: UTILS.SET_ASSURED_PROFILE_ID,
    assuredProfileId,
  }
}
export function setHaulSiteId(haulSiteId) {
  return {
    type: UTILS.SET_HAUL_SITE_ID,
    haulSiteId,
  }
}
export function setHaulSetup(haulSetup) {
  return {
    type: UTILS.SET_HAUL_SETUP,
    haulSetup,
  }
}
export function setSiteName(siteName) {
  return {
    type: UTILS.SET_SITE_NAME,
    siteName,
  }
}
export function setPanel(panel) {
  return {
    type: UTILS.SET_PANEL,
    panel,
  }
}
export function setTransactionId(id) {
  return {
    type: UTILS.SET_TRANSACTION_ID,
    transactionId: id
  }
}

export function setUserId(userId) {
  return {
    type: UTILS.SET_USER_ID,
    userId
  }
}
export function setToken(userId) {
  return {
    type: UTILS.SET_TOKEN,
    userId
  }
}
