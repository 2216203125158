import { SITES } from '../constants/types';

const initialState = {
  createSite: {
    inProgress: false,
    success: false,
    error: false,
    data: []
  },
  createSiteTeam: {
    inProgress: false,
    success: false,
    error: false,
    data: []
  },
  getSitesByUser: {
    inProgress: false,
    success: false,
    error: false,
    data: []
  },
}


export default (state = initialState, action) => {
  switch (action.type) {
    case SITES.CREATE_NEW_SITE_REQUESTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        createSite: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case SITES.CREATE_NEW_SITE_REJECTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        createSite: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case SITES.CREATE_NEW_SITE_FULFILLED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        createSite: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }

    case SITES.CREATE_SITE_TEAM_REQUESTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        createSiteTeam: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case SITES.CREATE_SITE_TEAM_REJECTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        createSiteTeam: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case SITES.CREATE_SITE_TEAM_FULFILLED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        createSiteTeam: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case SITES.GET_SITES_BY_USER_REQUESTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getSitesByUser: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case SITES.GET_SITES_BY_USER_REJECTED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getSitesByUser: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    case SITES.GET_SITES_BY_USER_FULFILLED: {
      const { inProgress, success, error, data } = action;
      const newState = Object.assign({}, state, {
        getSitesByUser: {
          inProgress,
          success,
          error,
          data
        }
      });
      return newState;
    }
    default:
      return state;
  }
}
