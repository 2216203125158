import {
    GET_STRIPE_CUSTOMER_REQUESTED,
    GET_STRIPE_CUSTOMER_REJECTED,
    GET_STRIPE_CUSTOMER_FULFILLED,

    GET_STRIPE_PAYMENT_METHOD_REQUESTED,
    GET_STRIPE_PAYMENT_METHOD_REJECTED,
    GET_STRIPE_PAYMENT_METHOD_FULFILLED,
} from './types';

import request from 'superagent';
import {url as config, assuredAPIURL} from '../constants';

export function getStripeCustomer(idcustomer){
    return dispatch => {
        dispatch(getStripeCustomerRequestedAction());
        request
        .get(`${assuredAPIURL}/stripe/customer/${idcustomer}`)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(getStripeCustomerFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(getStripeCustomerRejectedAction(error));
        });
    }
}

function getStripeCustomerRequestedAction() {
    return {
        type: GET_STRIPE_CUSTOMER_REQUESTED,
        inProgress: true
    };
}
  
function getStripeCustomerRejectedAction(error) {
    return {
        type: GET_STRIPE_CUSTOMER_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}
  
function getStripeCustomerFulfilledAction(data) {
    return {
        type: GET_STRIPE_CUSTOMER_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}

export function getStripePaymentMethod(idpayment){
    return dispatch => {
        dispatch(getStripePaymentMethodRequestedAction());
        request
        .get(`${assuredAPIURL}/stripe/payment/${idpayment}`)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(getStripePaymentMethodFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(getStripePaymentMethodRejectedAction(error));
        });
    }
}

function getStripePaymentMethodRequestedAction() {
    return {
        type: GET_STRIPE_PAYMENT_METHOD_REQUESTED,
        inProgress: true
    };
}
  
function getStripePaymentMethodRejectedAction(error) {
    return {
        type: GET_STRIPE_PAYMENT_METHOD_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}
  
function getStripePaymentMethodFulfilledAction(data) {
    return {
        type: GET_STRIPE_PAYMENT_METHOD_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}