import React, {Fragment, Component} from 'react';

export default class UnlockMapButton extends Component {

    constructor(props){
    	//Accepted props include a list of sites
    	super(props)
    	this.state = {}
	}


	btnClickListener = () => {
		let {setMapLocked} = this.props;
		setMapLocked(false);
	}

	cancel = () => {
		let {setMapLocked} = this.props;
		setMapLocked(true);
	}

	render(){

		let {className, children, mapLocked} = this.props;

		return(<Fragment>
			<button 
				className={className} 
				onClick={this.btnClickListener} 
				disabled={!mapLocked}>
				{children}</button>
			{!mapLocked ? <Fragment>
				<div class="alert alert-primary mt-1" role="alert">
				  <ol>
				  	<li>Plot location in map</li>
				  	<li>Add a name</li>
				  	<li>Click "Create Site"</li>
				  </ol>
				  <a href="#" onClick={this.cancel} className="text-link">Cancel</a>
				</div>				
			</Fragment> : null}
		</Fragment>)
	}

}