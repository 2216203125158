import { UTILS } from '../constants/types';

const initialState = {
  assuredSiteId: -1,
  haulSiteId: -1,
  haulSetup: false,
  assuredProfileId: 4,
  isPanelOpen: false,
  setPanelOrientation: false,
  siteName: undefined,
  transactionId: -1,
  userId: undefined,
  userEmail: undefined,
  tokenId: undefined
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UTILS.IS_PANEL_OPEN: {
      const { status } = action;
      const newState = Object.assign({}, state, {
        isPanelOpen: status
      });
      return newState;
    }
    case UTILS.SET_ASSURED_PROFILE_ID: {
      const { assuredProfileId } = action;
      const newState = Object.assign({}, state, {
        assuredProfileId
      });
      return newState;
    }
    case UTILS.SET_ASSURED_SITE_ID: {
      const { assuredSiteId } = action;
      const newState = Object.assign({}, state, {
        assuredSiteId
      });
      return newState;
    }
    case UTILS.SET_HAUL_SITE_ID: {
      const { haulSiteId } = action;
      const newState = Object.assign({}, state, {
        haulSiteId
      });
      return newState;
    }
    case UTILS.SET_HAUL_SETUP: {
      const { haulSetup } = action;
      const newState = Object.assign({}, state, {
        haulSetup
      });
      return newState;
    }
    case UTILS.SET_SITE_NAME: {
      const { siteName } = action;
      const newState = Object.assign({}, state, {
        siteName
      });
      return newState;
    }
    case UTILS.SET_PANEL_ORIENTATION: {
      const { status } = action;
      const newState = Object.assign({}, state, {
        setPanelOrientation: status
      });
      return newState;
    }

    case UTILS.SET_TRANSACTION_ID: {
      const { transactionId } = action;
      const newState = Object.assign({}, state, {
        transactionId
      })
      return newState;
    }
    case UTILS.SET_USER_ID: {
      const { userId } = action;
      const newState = Object.assign({}, state, {
        userId
      })
      return newState;
    }
    case UTILS.SET_USER_EMAIL: {
      const { userEmail } = action;
      const newState = Object.assign({}, state, {
        userEmail
      })
      return newState;
    }
    case UTILS.SET_TOKEN: {
      const { tokenId } = action;
      const newState = Object.assign({}, state, {
        tokenId
      })
      return newState;
    }
    default:
      return state;
  }
}
